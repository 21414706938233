import React from "react";
import PropTypes from "prop-types";

import {
	Box, Tooltip, Popover, IconButton, Typography, TextField, Select, MenuItem
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { HexColorPicker } from "react-colorful";

import {
	theme_settings, unFlattenPalette
} from "./MuiTheme.js";

class ThemeEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"show_editor" : false,
			"anchor" : null,
			"color_obj" : null,
			"color_arr" : null,
			"selected_color" : null,
		};

		this.toggleEditor = this.toggleEditor.bind(this);
		this.handleColorUpdate = this.handleColorUpdate.bind(this);
	}

	static propTypes = {
		"palette" : PropTypes.object.isRequired,
		"onChange" : PropTypes.func.isRequired
	};

	componentDidMount() {
		this.componentDidUpdate();
	}

	componentDidUpdate(prevProps) {
		const { palette } = this.props;
		const { selected_color } = this.state;
		const s1 = palette ? JSON.stringify(palette) : null;
		const s2 = prevProps ? JSON.stringify(prevProps?.palette) : null;

		if (s1 && s2 && s1 === s2) return;

		// Obtain a list of each unique color that can be tweaked.
		//let new_theme = {...theme_settings, "palette" : structuredClone(palette)};
		const color_obj = structuredClone(palette);
		delete color_obj["mode"];
		const color_arr = Object.keys(color_obj);

		const new_color = color_arr.includes(selected_color) ? selected_color : color_arr[0];
		this.setState({ color_obj, color_arr, "selected_color" : new_color });
	}

	render() {
		const {
			show_editor, anchor, color_arr, color_obj, selected_color
		} = this.state;

		if (!color_obj || !color_arr || !selected_color) return <div />

		return (
			<Box>
				<Tooltip
					title={"Customize theme"}
					slotProps={{
						"tooltip":{"sx":{"typography":"body2"}}
					}}
				>
					<IconButton onClick={this.toggleEditor}>
						<EditIcon
							sx={{
								"color" : "font.button",
								"&:hover" : {
									"cursor" : "pointer"
								}
							}}
						/>
					</IconButton>
				</Tooltip>
				<Popover
					open={show_editor}
					anchorEl={anchor}
					anchorOrigin={{
						"vertical" : "bottom",
						"horizontal" : "left"
					}}
					slotProps={{
						"paper" : {
							"sx" : {
								"margin" : "30px 0px 0px -100px",
								"padding" : "10px"
							}
						}
					}}
				>
					<Box
						className="flex-row-space-between"
					>
						<Typography color="font.main">Theme Editor</Typography>
						<IconButton
							onClick={this.toggleEditor}
						>
							<CloseIcon
								color="error"
								sx={{
									"&:hover" : {
										"cursor" : "pointer"
									}
								}}
							/>
						</IconButton>
					</Box>
					<Box className="flex-col-center">
						<Select
							name="ColorSelector"
							size="small"
							sx={{
								"typography" : "body3",
								"color" : "font.main"
							}}
							value={selected_color}
							onChange={evt => {
								const { value } = evt.target;
								this.setState({ "selected_color" : value });
							}}
						>
							{
								color_arr.map(k => {
									return (
										<MenuItem
											key={k}
											value={k}
											sx={{
												"typography" : "body3",
												"color" : "font.main"
											}}
										>{k}</MenuItem>
									);
								})
							}
						</Select>

						<TextField
							size="small"
							sx={{
								"width":"50%",
								"margin" : "20px 0px",
								"color" : "font.main"
							}}
							InputProps={{"sx":{"color":"font.main"}}}
							label={selected_color}
							value={color_obj[selected_color]}
							onChange={this.handleColorUpdate}
						/>

						<HexColorPicker
							key={selected_color}
							color={color_obj[selected_color]}
							onChange={this.handleColorUpdate}
						/>
					</Box>
				</Popover>
			</Box>
		);
	}

	toggleEditor(evt) {
		const { show_editor } = this.state;

		this.setState({
			"show_editor" : !show_editor,
			"anchor" : show_editor ? null : evt.target
		});
	}

	handleColorUpdate(v) {
		const { onChange } = this.props;
		const { color_obj, selected_color } = this.state;

		color_obj[selected_color] = v;
		this.setState({color_obj});

		console.log("Performing color update.");

		const new_theme = {
			...theme_settings,
			"palette" : unFlattenPalette(color_obj, "custom")
		};
		onChange(new_theme);
	}
}

export default ThemeEditor;

