import react from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import log from "loglevel";
import async from "async";

import { StrategyContext, NUMBER_RGX } from "../../context/StrategyContext.js";
import SimulationParameters from "../strategy-dashboard/SimulationParameters.js";
import StrategyBuilder from "../strategy-dashboard/StrategyBuilder.js";
import SimulationView from "../strategy-dashboard/simulation-view/SimulationView.js";

import {
	setAuthToken,
	createStrategy,
	getAllSecurities,
	getIndicatorPool,
	updateStrategy
} from "../../lib/strategy-ops.js";

import {
	Box,
	Button,
	Stepper,
	Step,
	StepLabel,
	StepContent
} from "@mui/material";

import { YEAR_MS } from "../../lib/duration-ops.js";
import { CATCHALL_GROUP_ID } from "../../lib/account-ops.js";

class Demo extends react.Component {
	constructor(props) {
		super(props);

		this.state = {
			"active_step" : -1,
			"catchall_group_id" : CATCHALL_GROUP_ID,
			"demo_token" : process.env["REACT_APP_DEMO_TOKEN"],
			"position_data" : null,
			"simulation" : {}
		};

		this.prevStep = this.prevStep.bind(this);
		this.nextStep = this.nextStep.bind(this);
		this.handleStrategyUpdate = this.handleStrategyUpdate.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"setContext" : PropTypes.func.isRequired,
		"is_disabled" : PropTypes.bool
	};

	componentDidMount() {
		const { is_disabled } = this.props;
		const { demo_token } = this.state;

		// Activate demo credentials.
		const expiry = new Date().getTime() + YEAR_MS;
		setAuthToken(demo_token, expiry);

		if (!is_disabled) this.nextStep();
	}

	componentWillUnmount() {
		setAuthToken(null, null); // Deactivate demo credentials.
	}

	componentDidUpdate(prevProps) {
		const was_disabled = prevProps.is_disabled;
		const is_disabled = this.props.is_disabled;

		// Determines when it is time to start the demo.
		if (was_disabled && !is_disabled) {
			this.setState({"active_step" : -1}, this.nextStep);
		}
	}

	render() {
		const { selected_leaf, setContext } = this.context;
		const { active_step, simulation } = this.state;
		const strategy_id = selected_leaf?.id;

		return (
			<Stepper
				className={"landing-stepper"}
				activeStep={active_step}
				orientation="vertical"
			>
				<Step
					key={"label-1"}
					sx={{
						"display" : "flex",
						"flexDirection" : "column",
					}}
				>
					<StepLabel
						sx={{"color":"font.button !important"}}
					>Customize Indicators and Signals</StepLabel>

					<StepContent>
					{/* The user has selected a strategy, opens editor and viewer. */}
						<StrategyBuilder
							is_dummy={false}
							onChange={this.handleStrategyUpdate}
						/>

						<Box
							sx={{
								"display" : "flex",
								"justifyContent" : "left"
							}}
						>
							<Button
								onClick={this.nextStep}
								variant="contained"
								color="primary"
								sx={{"color" : "font.button"}}
							>Next</Button>
						</Box>
					</StepContent>
				</Step>
				<Step
					key={"label-2"}
					sx={{
						"display" : "flex",
						"flexDirection" : "column",
					}}
				>
					<StepLabel
						sx={{"color":"font.main !important"}}
					>Simulation Parameters</StepLabel>
					<StepContent>
						{/* TODO: Replace this with some 'demo' strategy. */}
						<SimulationParameters
							strategy_id={strategy_id}
							demoSubmit={(a) => {
								this.setState({"simulation" : a}, () => {
									setContext({
										"is_loading" : false,
										"loading_msg" : null
									}, this.nextStep);
								});
							}}
						/>

						<Box
							sx={{
								"display" : "flex",
								"justifyContent" : "left"
							}}
						>
							<Button
								onClick={this.prevStep}
								color="secondary"
							>Back</Button>
						</Box>
					</StepContent>
				</Step>
				<Step key={"label-3"}>
					<StepLabel>Review Simulation</StepLabel>
					<StepContent>
						<Box className="flex-row-space-around">
							<Button
								onClick={() => {
									this.setState({
										"active_step" : -1,
										"position_data" : null,
										"simulation" : {}
									}, this.nextStep);
								}}
								variant="contained"
								color="secondary"
							>New Simulation</Button>

							<Link to="/account?is_login=false">
								<Button
									variant="contained"
									color="primary"
									sx={{"color" : "font.button"}}
								>Create Account</Button>
							</Link>
						</Box>

						{/* Displays the metrics (if there are any) once a strategy is selected. */}
						<SimulationView simulation_id={simulation["id"]} />

					</StepContent>
				</Step>
			</Stepper>
		);
	}

	prevStep() {
		const { active_step } = this.state;

		let new_step = active_step - 1;
		if (new_step < -1) new_step = -1;

		this.setState({ "active_step" : new_step });
	}

	nextStep() {
		const { selected_leaf } = this.context;
		const { setContext } = this.props;
		const { active_step, catchall_group_id } = this.state;

		let new_step = active_step + 1;
		if (new_step === 0) {
			// Creates strategy at beginning of demo.
			setContext({"is_loading" : true, "loading_msg" : "Loading demo..."});
			async.parallel([
				async.apply(createStrategy, catchall_group_id),
				getAllSecurities,
				getIndicatorPool
			], (err, results) => {
				if (err) {
					log.error(err);
					return;
				}

				setContext({
					"selected_leaf" : results[0],
					"security_pool" : results[1],
					"indicator_pool" : results[2],
					"setContext" : setContext,
					"is_loading" : false,
					"loading_failed" : false,
					"loading_msg" : null
				}, () => {
					this.setState({ "active_step" : new_step }, () => {
						setTimeout(() => {
							window.scrollTo(0, 800); // Scrolls to bottom
						}, 250);
					});
				});
			});
		} else if (new_step === 1) {
			// TODO: new_strat_id is deprecated and shouldn't be used anywehere'
			setContext({"is_loading" : true, "loading_msg" : "Saving strategy..."});
			updateStrategy(selected_leaf, err => {
				if (err) return log.error(err);

				setContext({
					"is_loading" : false,
					"loading_failed" : false,
					"loading_msg" : null
				}, () => {
					this.setState({ "active_step" : new_step }, () => {
						setTimeout(() => {
							window.scrollTo(0, 800); // Scrolls to bottom
						}, 250);
					});
				});
			});
		} else if (new_step > 2) {
			new_step = 2; // TODO: Magic number codesmell.
			this.setState({ "active_step" : new_step }, () => {
				setTimeout(() => {
					window.scrollTo(0, 800); // Scrolls to bottom
				}, 250);
			});
		} else {
			this.setState({ "active_step" : new_step }, () => {
				setTimeout(() => {
					window.scrollTo(0, 800); // Scrolls to bottom
				}, 250);
			});
		}
	}

	/**
	 * Updates the state with new strategy input. This funtion ensures that the
	 * strategy stored in the state updated on each edit.
	 *
	 * @param {*} evt The event that was triggered for the updated field.
	 */
	handleStrategyUpdate(evt, cb=null) {
		const { name, value } = evt.target;
		const { selected_leaf, setContext } = this.context;
		const onDone = (leaf) => {
			this.setState({ "can_save" : true }, () => {
				setContext({ "selected_leaf" : leaf }, cb);
			});
		};

		switch(name) {
			case "signals":
				selected_leaf["entrance_signals"] = value.filter(s => s.entrance_or_exit === "N");
				selected_leaf["exit_signals"] = value.filter(s => s.entrance_or_exit === "X");
				onDone(selected_leaf);
				break;
			default:
				if (value !== "" && !NUMBER_RGX.test(value)) return;
				selected_leaf[name] = Number(value);
				onDone(selected_leaf);
				break;
		}
	}
}

export default Demo;
