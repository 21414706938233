import React from "react";
import PropTypes from "prop-types";

import { getStrategySimulations } from "../../lib/strategy-ops.js";
import { StrategyContext } from "../../context/StrategyContext.js";

import log from "loglevel";

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
	Button, Box, Typography, Grid, FormControlLabel, Checkbox,
	Tooltip, IconButton, Icon, CircularProgress
} from "@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DownloadIcon from '@mui/icons-material/Download';
import SettingsIcon from "@mui/icons-material/Settings";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import CenterModal from "../CenterModal.js";

// import {
// 	buildPositionsCsv
// } from "../../../lib/strategy-ops.js";

import { SIMULATION_METRICS } from "../../lib/metric-ops.js";

class SimulationTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"selected_headers" : SIMULATION_METRICS.filter(pm => pm["is_default"]),
			"new_selected_headers" : SIMULATION_METRICS.filter(pm => pm["is_default"]),
			"show_selector" : false,
			"selected_column" : 3, // Mocking 'Total Return', the default
			"sort_direction" : true,
			"is_loading" : false,
			"page" : 1
		};

		this.renderHeaderOptions = this.renderHeaderOptions.bind(this);
		this.handlePagination = this.handlePagination.bind(this);
		this.buildSimulationsCsv = this.buildSimulationsCsv.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"simulations" : PropTypes.array.isRequired
	};

	componentDidUpdate(prevProps) {
		const { simulations } = this.props;
		const { selected_simulation, setContext } = this.context;


		if (!selected_simulation && simulations?.length) {
			setContext({ "selected_simulation" : simulations[0]});
		}
	}

	render() {
		const {
			selected_simulation, selected_leaf, setPage, sim_page_size
		} = this.context;
		const { simulations } = this.props;
		const {
			selected_headers, new_selected_headers, show_selector,
			selected_column, sort_direction, page
		} = this.state;

		const is_loading = this.state.is_loading ||
							this.props.is_loading ||
							!simulations ||
							!selected_simulation;

		// if (!simulations || !selected_simulation) return <div />

		let is_pressing_ctrl = false;
		const ctrl_keycode = 17;
		window.addEventListener("keydown", e => {
			if (!is_pressing_ctrl && e.keyCode === ctrl_keycode) {
				is_pressing_ctrl = true;
			}
		});
		window.addEventListener("keyup", e => {
			if (is_pressing_ctrl && e.keyCode === ctrl_keycode) {
				is_pressing_ctrl = false;
			}
		});

		const pagination_sx = {
			"fontSize" : "3.0em",
			"color": "secondary.main"
		};


		const total_sims = is_loading ? 0 : selected_leaf["total_simulations"];
		const max_pages = Math.max(1, parseInt(total_sims / sim_page_size));
		const ttl = selected_leaf["last_refresh_time"] || undefined;

		const total_sims_s = total_sims > 0 ? ` (${total_sims.toLocaleString()})` : "";

		return (
			<Box
				id="simulation-table"
				className="simulation-table"
				sx={{"marginTop" : "60px"}}
			>
				<Box className="flex-row-space-between">
					<Box className="flex-row-center">
						<Typography
							color="font.main"
							variant="h2"
						>All Simulations{total_sims_s}</Typography>

						<Tooltip title="Customize Columns">
							<IconButton
								onClick={() => {
									this.setState({"show_selector" : true});
								}}
							>
								<SettingsIcon sx={{"color":"font.main"}} />
							</IconButton>
						</Tooltip>
						<Tooltip title="Download Simulations">
							<IconButton onClick={this.handleDownload}>
								<DownloadIcon color="secondary" sx={{"typography" : "h2"}} />
							</IconButton>
						</Tooltip>
						{
							is_loading &&
							<Box className="flex-row-center">
								<CircularProgress />
								<Typography
									color="font.main"
									variant="body1"
									sx={{"marginLeft" : "20px"}}
								>Loading Simulations</Typography>
							</Box>
						}
					</Box>
					<Box
						className="flex-row-center"
						sx={{"alignItems" : "center"}}
					>
						{
							ttl &&
							<Typography
								color="font.main"
								variant="body2"
							>Refreshed at {ttl.toLocaleString()}</Typography>
						}
						<IconButton
							component={SkipPreviousIcon}
							sx={pagination_sx}
							disabled={page === 1}
							onClick={() => {
								this.handlePagination(1, selected_column, sort_direction);
							}}
						/>
						<IconButton
							component={ChevronLeftIcon}
							sx={pagination_sx}
							disabled={page === 1}
							onClick={() => {
								this.handlePagination(page - 1, selected_column, sort_direction);
							}}
						/>

						<Box>
							<Typography color="font.main">
								{page}
							</Typography>
						</Box>

						<IconButton
							component={ChevronRightIcon}
							sx={pagination_sx}
							disabled={page === max_pages}
							onClick={() => {
								this.handlePagination(page + 1, selected_column, sort_direction);
							}}
						/>
						<IconButton
							component={SkipNextIcon}
							sx={pagination_sx}
							disabled={page === max_pages}
							onClick={() => {
								this.handlePagination(max_pages, selected_column, sort_direction);
							}}
						/>
					</Box>
				</Box>

				<TableContainer component={Paper}>
					<Table sx={{}}>
						<TableHead>
							<TableRow>
								{selected_headers.map(sh => {
									return (
										<TableCell
											key={sh["id"]}
											sx={{
												"color" : "font.main",
												"&:hover" : {
													"cursor" : "pointer",
													"backgroundColor" : "rgba(0,170,237,0.08)",
												}
											}}
											onClick={() => {
												let new_dir = (sh.id !== selected_column) ? true : !sort_direction;
												this.handlePagination(page, sh.id, new_dir);
											}}
										>

										<Box className="flex-row-center">
											{sh["label"]}
											{
												(sh.id === selected_column) &&
												<Icon
													component={sort_direction ? KeyboardArrowDownIcon : KeyboardArrowUpIcon}
												/>
											}

										</Box>
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>

						<TableBody>
							{/* Iteratively generates a row for each position. */}
							{
								!!total_sims && simulations.map((p, idx) => {

								let gid = selected_leaf["group_id"];
								let sid = selected_leaf["id"];
								let new_path = "/dashboard";
								new_path += `/group-${gid}`;
								new_path += `/strategy-${sid}`;
								new_path += `/simulation-${p.id}`;

								// Adding these non-metric properties for use with selected_headers
								p["metrics"]["id"] = simulations[idx]["id"];
								p["metrics"]["start_time"] = simulations[idx]["start_time"];
								p["metrics"]["stop_time"] = simulations[idx]["stop_time"];

								return (
									<TableRow
										className={"position-row"}
										key={p["id"]}
										onClick={() => {
											if (is_pressing_ctrl) {
												is_pressing_ctrl = false;
												window.open(new_path, "_blank");
											} else {
												setPage(new_path);
											}
										}}
									>
										{selected_headers.map(sh => {
											const v = sh.calculate(p["metrics"]);

											return (
												<TableCell
													key={sh["id"]}
													align="center"
													sx={{
														"color" : "font.main"
													}}
												>{v}</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>


				<CenterModal
					is_open={show_selector}
					title="Select Which Headers to Display"
					onClose={() => {
						this.setState({"show_selector" : false});
					}}
				>
						<Grid
							container
							spacing={0}
							sx={{
								"width" : "100%",
								"height" : "70%",
								"display" : "flex",
								"flexDirection" : "row",
								"justifyContent" : "space-around",
								"alignItems" : "start"
							}}
						>
							{ this.renderHeaderOptions() }
						</Grid>

						<Button
							variant="contained"
							sx={{
								"color":"font.button",
								"marginTop" : "30px"
							}}
							onClick={() => {
								this.setState({
									"show_selector" : false,
									"selected_headers" : new_selected_headers
								});
							}}
						>Save</Button>
				</CenterModal>
			</Box>
		);
	}

	renderHeaderOptions() {
		const { new_selected_headers } = this.state;

		const handleCheck = (po, is_checked) => {
			if (is_checked) {
				this.setState({
					"new_selected_headers" : new_selected_headers.filter(sh => sh.id !== po.id)
				});
			} else {
				this.setState({
					"new_selected_headers" : [ ...new_selected_headers, po ]
				});
			}
		}

		return SIMULATION_METRICS.map(po => {
			const is_checked = new_selected_headers.map(sh => sh.id).includes(po.id);

			return (
				<Grid
					item
					key={po["id"]}
					xs={4}
					className="simulation-header-label"
				>
					<FormControlLabel
						label={po["label"]}
						control={
							<Checkbox
								checked={is_checked}
								onChange={e => {
									handleCheck(po, is_checked);
								}}
							/>
						}
					/>
				</Grid>
			);
		});
	}


	handlePagination(page, sort, direction) {
		const {
			selected_leaf, simulations, setContext, sim_page_size
		} = this.context;
		const sid = selected_leaf.id;
		this.setState({ "is_loading" : true });

		setContext({
			"sim_page" : page,
			"metric_sort_col" : sort,
			"metric_sort_direction" : direction ? "desc" : "asc"
		}, () => {
			getStrategySimulations(sid,
									page,
									sim_page_size,
									sort,
									direction ? "desc" : "asc",
			(err, res) => {
				if (err) {
					console.error(err);
					setContext({
						"snackbar_msg" : err.toString(),
						"snackbar_sev" : "error"
					});
					return;
				}

				this.setState({
					page,
					"is_loading" : false,
					"selected_column" : sort,
					"sort_direction" : direction
				}, () => {
					simulations[`strategy-${sid}`] = res["simulations"];
					setContext({simulations});
				});
			});
		});
	}

	buildSimulationsCsv(simulations) {
		let csv = "";
		let n = simulations.length;
		let m = SIMULATION_METRICS.length;

		for (let i = 0; i < m; i += 1) {
			csv += `"${SIMULATION_METRICS[i].label}"`;
			if (i < (m - 1)) csv += ",";
			else csv += "\r\n";
		} 

		for (let i = 0; i < n; i += 1) {
			const s = simulations[i];
			// Adding these non-metric properties for use with selected_headers
			s["metrics"]["id"] = s["id"];
			s["metrics"]["start_time"] = s["start_time"];
			s["metrics"]["stop_time"] = s["stop_time"];

			for (let j = 0; j < m; j += 1) {
				const sm = SIMULATION_METRICS[j];
				const v = sm.calculate(s["metrics"]);
				csv += `"${v}"`;

				if (j < (m - 1)) csv += ",";
				else csv += "\r\n";
			}
		}

		return csv;
	}

	handleDownload() {
		const { selected_leaf, setContext } = this.context;
		const { "id" : sid, total_simulations } = selected_leaf;
		const filename = `strategy-${sid}-simulations.csv`;

		setContext({
			"is_loading" : true,
			"loading_msg" : "Downloading all simulations..."
		}, () => {
			getStrategySimulations(sid, 1, total_simulations, 3, "desc", (err, res) => {
				if (err) {
					log.error(err);
					setContext({
						"is_loading" : false,
						"loading_msg" : null,
						"snackbar_msg" : err.toString(),
						"snackbar_sev" : "error"
					});
					return;
				}

				const sims = res.simulations;
				const csv = this.buildSimulationsCsv(sims);
				const blob = new Blob([csv], {"type":"text/csv"});

				setContext({
					"is_loading" : false,
					"loading_msg" : null
				}, () => {
					const a = document.createElement("a");
					a.download = filename;
					a.href = window.URL.createObjectURL(blob);
					a.dispatchEvent(new MouseEvent("click", {
						"view" : window,
						"bubble" : true,
						"cancelable" : true
					}));
				});
			});
		});
	}
}

export default SimulationTable;
