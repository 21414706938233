import React from "react";

import { EMAIL_RGX } from "../../context/AccountContext.js";

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"email" : ""
		};
		
		/* TODO: Experiment with whether or not these are required. */
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.validEmail = this.validEmail.bind(this);
		this.validForm = this.validForm.bind(this);
	}

	static propTypes = {};

	render() {
		return (
			<div>
				<h3>Welcome to Password Reset.</h3>
				<form className="ResetPasswordForm" onSubmit={this.handleSubmit}>
					{
						this.state.email !== "" &&
						!this.validEmail(this.state.email) &&
						<label>Invalid Email.</label>
					}
					<label>Email:</label>
					<input type="email" name="email" onChange={this.handleChange} />
					<br />

					<input type="submit" value="Log in" disabled={!this.validForm()}/>
				</form>
			</div>
		);
	}
	
	handleSubmit(e) {
		e.preventDefault();
		let email_address = e.target.elements.email.value;
		
		console.log(`Resetting password for ${email_address}.`);
		// TODO: This does not currently exist as an endpoint on the server. 
		// axios({
		//	 "method" : "POST",
		//	 "url" : "/reset-password",
		//	 "data" : {email_address}
		// }).then(res => {
		//	 console.log("Received password reset response:");
		//	 console.log(res);
		// }).catch(err => {
		//	 console.error("Failed to reset password:");
		//	 console.error(err);
		// });
	}
	
	handleChange(e) {
		this.setState({[e.target.name] : e.target.value});
	}
	
	validEmail(e) {
		return EMAIL_RGX.test(e.toUpperCase());
	}
	
	validForm() {
		return typeof this.state.email === "string" &&
				this.validEmail(this.state.email);
	}
}

export default ResetPassword;
