import axios from "axios";

import { loadStripe } from "@stripe/stripe-js";

// DataCollector:
// https://developer.paypal.com/braintree/docs/guides/premium-fraud-management-tools/client-side

const { REACT_APP_STRIPE_KEY } = process.env;

export const STRIPE_INSTANCE = loadStripe(REACT_APP_STRIPE_KEY);


export const PRICE_PLANS = [
	{
		"plan_id" : "one-month",
		"price" : "$50.00",
		"label" : "One Month Membership"
	},
	{
		"plan_id" : "one-year",
		"price" : "$500.00",
		"label" : "One Year Membership"
	},
];

export const fetchCustomer = (cb) => {
	axios({
		"method" : "GET",
		"url" : "/payment/"
	}).then(res => {
		return cb(null, res.data);
	}).catch(cb);
};

export const fetchClientSecret = (is_autopay, cb) => {
	axios({
		"method" : "GET",
		"url" : "/payment/session",
		"params" : { is_autopay }
	}).then(res => {
		return cb(null, res.data.client_secret);
	}).catch(cb);
};

export const turnOffAutopay = (cb) => {
	axios({
		"method" : "PATCH",
		"url" : "/payment/subscription",
	}).then(res => {
		return cb(null);
	}).catch(cb);
};

export const deletePaymentMethod = (cb) => {
	axios({
		"method" : "DELETE",
		"url" : "/payment/method",
	}).then(res => {
		return cb(null);
	}).catch(cb);
};

