// React Imports
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// MaterialUI Imports
import {
	Box, Switch, Toolbar, Typography,
	List, ListItem, ListItemText, ListItemIcon
} from "@mui/material";
import {Home} from "@mui/icons-material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import WbSunnyIcon from '@mui/icons-material/WbSunny';

// Custom Imports
import "./../css/navbar.css";
import { AccountContext } from "./../context/AccountContext";
import {
	theme_settings, palette_light, palette_dark
} from "./MuiTheme.js";
import ThemeEditor from "./ThemeEditor.js";

const SUN_COLOR = "#ffdf00";
const MOON_COLOR = "#91a3b0";

class Navbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"theme_editor_visible" : false
		};
	}

	static contextType = AccountContext;
	static propTypes = {
		"onThemeUpdate" : PropTypes.func.isRequired
	};

	render() {
		const { is_authenticated, customizations } = this.context;
		const { onThemeUpdate } = this.props;

		const mode = customizations?.theme?.mode || "light";

		return (
			<Toolbar
				className="navbar"
				sx={{"backgroundColor" : "secondary.main",}}
			>
				<List component="nav" className="navbar-list">
					<Box className="box">
						<Box sx={{ "display" : "flex", "height":"100%" }}>
							<Link to="/">
								<ListItem button sx={{"height":"100%"}}>
									<ListItemIcon className="navbar-list-item">
										<Home sx={{"color" : "font.button"}} />
									</ListItemIcon>
								</ListItem>
							</Link>

							<Link to="/about">
								<ListItem button sx={{"height":"100%"}}>
									<ListItemText
										sx={{"color":"font.button"}}
										className="navbar-list-item"
										primary="About"/>
								</ListItem>
							</Link>

							<Typography
								color="font.button"
								variant="subtitle1"
								className="navbar-separator"
							>|</Typography>

							<Link to={`/dashboard`}>
								<ListItem button sx={{"height":"100%"}}>
									<ListItemText
										className="navbar-list-item"
										sx={{"color":"font.button"}}
										primary="Dashboard"
									/>
								</ListItem>
							</Link>

							{/*<Typography color="font.main" variant="subtitle1" color="white">|</Typography>

							<Link to={`/monitoring`}>
								<ListItem button>
									<ListItemText className="navbar-list-item" primary="Monitoring" />
								</ListItem>
							</Link>*/}

							<Typography
								color="font.button"
								variant="subtitle1"
								className="navbar-separator"
							>|</Typography>

							<Link to="/pricing">
								<ListItem button sx={{"height":"100%"}}>
									<ListItemText sx={{"color":"font.button"}} className="navbar-list-item" primary="Pricing" />
								</ListItem>
							</Link>

							<Typography
								color="font.button"
								variant="subtitle1"
								className="navbar-separator"
							>|</Typography>

							<Link to="/support">
								<ListItem button sx={{"height":"100%"}}>
									<ListItemText
										className="navbar-list-item"
										sx={{"color" : "font.button"}}
										primary="Support"
									/>
								</ListItem>
							</Link>
						</Box>
						<Box sx={{ "display" : "flex","height":"100%"}}>
							{
								is_authenticated &&
								<Box
									className="flex-row-center"
									sx={{ "marginRight" : "20px"}}
								>
									<ThemeEditor
										mode={mode}
										palette={customizations.theme}
										onChange={new_theme => {
											onThemeUpdate("custom", new_theme);
										}}
									/>
									<NightlightRoundIcon sx={{"color" : MOON_COLOR}} />
									<Switch
										color="primary"
										checked={mode === "light"}
										onChange={(evt) => {
											const new_mode = mode === "light" ? "dark" : "light";
											const new_palette = new_mode === "light" ? palette_light : palette_dark;
											const new_theme = {
												...theme_settings,
												"palette" : structuredClone(new_palette)
											};
											onThemeUpdate(new_mode, new_theme);
										}}
									/>
									<WbSunnyIcon sx={{"color" : SUN_COLOR}} />
								</Box>
							}

							<Link to="/account">
								<ListItem button sx={{"height":"100%"}}>
									<ListItemText
										sx={{"color" : "font.button"}}
										className="navbar-list-item"
										primary={is_authenticated ? "Profile" : "Sign in"} />

									<ListItemIcon sx={{"marginLeft" : "10px"}}>
										<AccountCircleIcon sx={{"color" : "font.button"}} />
									</ListItemIcon>
								</ListItem>
							</Link>
						</Box>
					</Box>
				</List>
			</Toolbar>
		);
	}
}

export default Navbar;
