import React from "react";

const { REACT_APP_SESSION_DURATION } = process.env;
if (!REACT_APP_SESSION_DURATION) {
	console.error("Session duration not available.");
}
const SESS_DUR = parseInt(REACT_APP_SESSION_DURATION);

/* Useful constants */
export const EMAIL_RGX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
/* Determines how frequently session should poll for activity. */
export const ACTIVITY_INTERVAL_MS = parseInt(SESS_DUR / 5);

/* React Context */
export const defaultAccountContext = {
	"account_id" : null,
	"is_authenticated" : false,
	"membership_tier" : "demo",
	"membership_expiration" : null,
	"username" : null,
	"email" : null,
	"created_at" : null,
	"is_loading" : false,
	"loading_msg" : null,
	"is_loaded" : false,
	"is_expired" : false,
	"customizations" : {
		"cookies_acknowledged" : false,
		"theme" : {
			"primary" : "#00aaed",
			"secondary" : "#673ab7",
			"success" : "#66bb6a",
			"info" : "#00aaed",
			"warning" : "#ffa726",
			"error" : "#ff4d00",
			"background" : "#f0f0f0",
			"paper" : "#f9f9f9",
			"font1" : "#000000",
			"font2" : "#ffffff",
			"mode" : "light"
		},
		"simulation_columns" : [0, 1, 2, 3, 7, 8, 9],
		"simulation_sort" : 3,
		"position_colunns" : [0, 1, 2, 3, 4, 5],
		"position_sort" : 0,
	}
};

export const AccountContext = React.createContext(defaultAccountContext);
