import React from "react";
//import PropTypes from "prop-types";

import { Container, Typography } from "@mui/material";

class TermsConditions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// static contextType = undefined;
	static propTypes = {};

	render() {

		const ty_props = {
			"color" : "font.main",
			"variant" : "body1",
			"sx" : {
				"textAlign" : "left",
				"marginLeft" : "20px"
			}
		};

		return (
			<Container>
				<Typography color="font.main" variant="subtitle1">1. Introduction</Typography>
				<Typography {...ty_props}>
					Welcome to StratSim.io, a comprehensive online platform offering a variety of technical analysis tools designed for analyzing financial market data. Our service provides users with access to a suite of features, including charts, indicators, and other data visualization tools that facilitate the understanding of market trends and financial data.
				</Typography>

				<Typography color="font.main" variant="subtitle1">2. Acceptance of Terms</Typography>
				<Typography {...ty_props}>
					By accessing and using our website and its services, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions in their entirety. If you do not agree with these terms and conditions, you must not use our website or any services provided therein.
				</Typography>

				<Typography color="font.main" variant="subtitle1">3. Registration and Account Security</Typography>
				<Typography {...ty_props}>
					In order to use our services, you are required to register for an account on our website. During registration, you must provide accurate and complete information as prompted by the registration form. You are responsible for maintaining the confidentiality of your account credentials, including your password. You agree to notify us immediately of any unauthorized use of your account or any other breach of security. We reserve the right to take appropriate action, including the termination of your account, in response to any fraudulent, abusive, or illegal activities associated with your account.
				</Typography>

				<Typography color="font.main" variant="subtitle1">4. Payment and Refund Policy</Typography>
				<Typography {...ty_props}>
					Access to our services requires a monthly subscription fee, which is billed in advance on a recurring basis. In the event that your payment fails, your access to the service may be temporarily suspended until we receive a successful payment. You may cancel your subscription at any time through your account settings or by contacting our support team. However, we do not provide refunds for partially used subscription periods or for any unused portion of the service.
				</Typography>

				<Typography color="font.main" variant="subtitle1">5. Service Usage and Restrictions</Typography>
				<Typography {...ty_props}>
					Our services are intended solely for your personal, non-commercial use. You agree not to sell, reproduce, distribute, or otherwise exploit any portion of our services or content without our prior written consent. Unauthorized use of our services may result in the termination of your access and may expose you to legal action.
				</Typography>

				<Typography color="font.main" variant="subtitle1">6. Intellectual Property Rights</Typography>
				<Typography {...ty_props}>
					All content, including but not limited to text, graphics, logos, images, and software, on our website is the property of StratSim.io or its content suppliers and is protected by copyright, trademark, and other intellectual property laws. You are granted a limited, non-exclusive, non-transferable license to access and use our content for your personal, non-commercial purposes as permitted by these terms and conditions.
				</Typography>

				<Typography color="font.main" variant="subtitle1">7. Privacy Policy</Typography>
				<Typography {...ty_props}>
					Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and protect your personal information when you access and use our website and services.
				</Typography>

				<Typography color="font.main" variant="subtitle1">8. Limitation of Liability</Typography>
				<Typography {...ty_props}>
					To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or use, arising out of or in connection with your use of our website, services, or content, whether based on contract, tort,  
				</Typography>

				<Typography color="font.main" variant="subtitle1">9. Your Obligations</Typography>
				<Typography {...ty_props}>
					As a user of our services, you commit to providing us with accurate and complete information when creating your account, during our ongoing relationship, and in all your correspondences with us. We may occasionally communicate with you about our services via email or telephone. You agree to maintain an active, regularly monitored email address and to promptly update us with any changes to your contact information. We bear no liability for any service interruptions or damages arising from misdirected communications due to outdated or incorrect contact information. You further commit to completing any data entitlement surveys truthfully and completely to ensure compliance with exchange regulations.
					If you permit third-party use of our platform, you agree to (i) restrict their use in accordance with these terms of service and any additional documentation we provide to you, (ii) ensure that the third party adheres to these terms of service and our privacy policy, and (iii) accept responsibility for any violations of these terms of service or our privacy policy by the third party.
					Our platform may feature trademark or other ownership notices. You agree not to conceal or remove these notices. Use of the platform must comply with the laws of the jurisdiction governing these terms of service and your current location.
				</Typography>

				<Typography color="font.main" variant="subtitle1">10. Term</Typography>
				<Typography {...ty_props}>
					These terms of service become effective when you first access our platform or click "I accept," whichever occurs first. The agreement remains in effect at all times while an order is open.
					The services renew monthly, quarterly, or annually, as specified in your order, with the first such term referred to as the Initial Term. Following the expiration of the Initial Term, the services will renew for successive periods of equal length (each, a "Renewal Term"). If no Initial Term is stated in the order, the Initial Term will be one month, and each Renewal Term will be one month.
					In certain cases, we may offer a trial period, which may be free or require a fee, as indicated on the trial signup page. During the trial period, the same terms and conditions apply as if you were a paid subscriber. You can cancel anytime during the trial period to prevent future automated renewal. It is your responsibility to manage your subscription.
				</Typography>

				<Typography color="font.main" variant="subtitle1">11. Payment</Typography>
				<Typography {...ty_props}>
					You are responsible for the payment of all fees and charges outlined in your order, including setup fees and recurring subscription fees (collectively, "Fees"). Fees are charged no more than three days in advance of the date set out in your order. All fees must be paid in US dollars via a major credit card. All renewal charges will be automatically charged to the same credit card on the due date of the payment.
					If your account is set up for automatic renewal, you authorize us to automatically charge your payment card for the Fees. If your bank declines the payment, our payment processor may attempt to retry the transaction one or more times until payment is approved.
					Our obligation to provide the services is contingent on your payment of the fees by the due date stated on our invoice. It is your responsibility to ensure that we receive payment of the fees. In the event fees are not paid timely, we may, at our sole discretion, suspend the services, charge interest at a rate of 2.0% per month on all unpaid balances, and/or cancel any order.
					We reserve the right to adjust the cost of the services. We will notify you of any changes to the cost of your services at least 30 days before such change is to take effect. Any change to service pricing will be effective on the next renewal date after 30 days have passed from the date we sent notification to you of the price.
				</Typography>

				<Typography color="font.main" variant="subtitle1">12. Representations and Warranties; Compliance</Typography>
				<Typography {...ty_props}>
					Each party involved in this agreement warrants to the other that: (i) they possess the necessary power, authority, and legal right to enter into these terms of service and any relevant order, and (ii) they possess the necessary power, authority, and legal right to fulfill their obligations under these terms of service and all incorporated provisions.
					You, as a user of our platform, represent and warrant to StratSim that: (i) you have the requisite experience and knowledge to use the platform effectively and appropriately, (ii) you will not solely rely on the information provided by StratSim, and will conduct your own due diligence before making any investment or trade decisions.
				</Typography>

				<Typography color="font.main" variant="subtitle1">13. Compliance</Typography>
				<Typography {...ty_props}>
					As a user, you agree not to use our platform in any manner or for any purpose that would contravene, or result in the contravention of, any applicable laws, rules, or regulations, or any rights of third parties. This includes, without limitation, any laws or regulations governing public securities, markets, and trading. Any violation of this section could result in the immediate termination of your access to our service, without any notice or dispute resolution process.
					While StratSim makes every effort to ensure the accuracy and reliability of the information provided on our platform, we do not guarantee or warrant the absolute accuracy, completeness, or timeliness of any such information. The data and information provided on our platform are intended to support your personal trading activities and should not be the sole basis for any trading or investment decisions.
					You acknowledge and agree that all decisions based on the information provided on our platform are your sole responsibility. StratSim will not be liable for any errors or omissions in the information provided, or for any decisions you make or actions you take based on such information.
					Furthermore, you understand and agree that the market data, news, and other information available on our platform are subject to change and can be affected by a variety of factors beyond our control. As such, StratSim cannot be held responsible for any losses or damages, whether direct, indirect, incidental, or consequential, that may arise from any reliance on the information provided on our platform.
					By using our platform, you accept the risk that the information may contain errors or omissions and agree to independently verify any information you intend to rely upon.
				</Typography>

			</Container>
		);
	}
}

export default TermsConditions;

