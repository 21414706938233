import React from "react";
import PropTypes from "prop-types";

import { formatMoney } from "../lib/string-validation.js";

import {
	Box, Button, Paper, Grid, Typography,
	/*Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,*/
} from "@mui/material";

class PricingPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.renderProBenefits = this.renderProBenefits.bind(this);
	}

	static propTypes = {
		"match" : PropTypes.object.isRequired,
		"history" : PropTypes.object.isRequired,
		"location" : PropTypes.object.isRequired
	}

	render() {
		const { history } = this.props;

		// TODO: Copy the adverts from the Profile page
		// TODO: Add a table with the pricing information
		// TODO: Explain some of the features in more detail
		// TODO: Add the button to create account/upgrade.

		/*const table_headers = [
			"Plan Name", "Demo", "Pro Tier 1", "Pro Tier 2", "Pro Tier 3"
		];

		const table_rows = [
			{ "k" : "one-month", "data" : [ "One Month", "Free", "$40.00", "$50.00", "$60.00" ]},
			{ "k" : "twelve-month", "data" : [ "One Year", "Free", "$336.00", "$420.00", "$504.00" ]},
		];*/

		const price = formatMoney(50);

		return (
			<Box className="flex-col-center pricing-box">
				<Typography 
					variant="h1"
					color="font.main"
					sx={{ "textAlign" : "center", "margin" : "20px"}}
				>StratSim Pricing</Typography>

				<Typography
					variant="subtitle1"
					color="font.main"
					sx={{ "textAlign" : "center", "margin" : "20px" }}
				>
					Currently there is only a single membership option for StratSim, "StratSim Pro" for {price} USD per month. More membership options will be coming soon!
				</Typography>
				{/*<Typography
					variant="subtitle1"
					color="font.main"
					sx={{ "textAlign" : "center", "margin" : "20px" }}
				>
					StratSim Pro offers a variety of advanced features to help you test and find-tune your strategies.
				</Typography>

				<Grid className="pro-benefits-grid" container spacing={0}>
					{ this.renderProBenefits() }
				</Grid>

				<Typography 
					variant="h2"
					color="font.main"
					sx={{
						"textAlign" : "left",
						"margin" : "60px 0px 20px 0px",
					}}
				>Pricing Plans</Typography>
				<TableContainer
					component={Paper}
				>
					<Table>
						<TableHead>
							<TableRow>
								{
									table_headers.map(th => {
										return (
											<TableCell key={th}>
												<Typography
													color="font.main"
													variant="body2"
												>{th}</Typography>
											</TableCell>
										);
									})
								}
							</TableRow>
						</TableHead>
						<TableBody>
							{ table_rows.map(tr => { return (
									<TableRow key={tr.k}>
										{ tr.data.map(c => { return (
											<TableCell key={`${tr.k}-${c}`}>
												<Typography
													color="font.main"
													variant="body2"
												>{c}</Typography>
											</TableCell>
										); }) }
									</TableRow>
								);}) }
						</TableBody>
					</Table>
				</TableContainer>*/}

				<Button
					variant="contained"
					onClick={() => {
						history.push("/account");
					}}
					sx={{
						"color" : "font.button",
						"margin" : "20px 10px 20px 10px"
					}}
				>Account Page</Button>
			</Box>
		);
	}

	renderProBenefits() {
		const arr = [
			{
				"title" : "Increased Daily Simulations",
				"desc" : "Demo Accounts are limited to 100 Simulations per day."},
			{
				"title" : "Design Strategy Templates",
				"desc" : "Templates allow you to specify guidelines for generating new strategies"
			},
			{
				"title" : "Continuous Engine Submission",
				"desc" : "Automatically submit templates and strategies to the engine, hands free."
			},
			{
				"title" : "Automated Forward Testing",
				"desc" : "Satisfied with a strategy? See how it performs in real time on real data."
			},
			{
				"title" : "Optional Trade Notifications",
				"desc" : "Connect your phone or email and receive live updates on your templates and forward tests."
			},
			{
				"title" : "Access to Continuous Monitoring Tool ",
				"desc" : "A separate dashboard specifically for monitoring continuous submissions."
			}
		];

		const grid_item_props = {
			"className" : "pro-benefits-tile",
			"item" : true,
			"component" : Paper,
			"xs" : 4
		};

		return arr.map(a => {
			return (
				<Grid key={a["title"]} { ...grid_item_props }>
					<Typography color="secondary" variant="body2">{a["title"]}</Typography>
					<Typography color="font.main" variant="body3">{a["desc"]}</Typography>
				</Grid>
			);
		});
	}

}

export default PricingPage;

