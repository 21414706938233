import React from "react";
import PropTypes from "prop-types";

import { Modal, Box, Typography, IconButton } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

class CenterModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	// static contextType = undefined;
	static propTypes = {
		"is_open" : PropTypes.bool.isRequired,
		"onClose" : PropTypes.func,
		"title" : PropTypes.string.isRequired,
		"sx" : PropTypes.object,
	}

	render() {
		const { is_open, title, onClose, sx } = this.props;

		return (
			<Modal
				open={is_open || false}
				disableAutoFocus
				disableEnforceFocus
				onClose={onClose ? onClose : () => {}}
			>
				<Box
					className={"center-overlay-modal"}
					sx={{
						"backgroundColor" : "background.paper",
						"borderWidth" : "2px",
						"borderStyle" : "solid",
						"borderColor" : "secondary.main",
						...sx
					}}
				>
					<Box className="flex-row-space-between">
						<Typography
							variant="body1"
							color="secondary"
						>{title}</Typography>

						{
							!!onClose &&
							<IconButton onClick={onClose}>
								<CloseIcon color="error" />
							</IconButton>
						}
					</Box>
					<Box sx={{"padding" : "20px"}}>
						{this.props.children}
					</Box>
				</Box>
			</Modal>
		);

	}
}

export default CenterModal;
