import { msToDuration } from "./duration-ops.js";

const PIP_FACTOR = 10000;
/*const ONE_MINUTE = 1000 * 60;*/
/*const ONE_HOUR = ONE_MINUTE * 60;*/
const formatRoi = (r, p) => {
	return ((r - 1.0) * 100.0).toFixed(p) + "%";
}

/*
 * TODO: Convert this to a DB / API call. The table metric_field has been schemad for this
 */
export const SIMULATION_METRICS = [
	{
		"id" : 0,
		"label" : "ID",
		"getRaw" : m => m["id"],
		"calculate" : m => m["id"],
		"is_default" : true,
		"is_metric" : false,
	},
	{
		"id" : 1,
		"label" : "Start Time",
		"getRaw" : m => m["start_time"],
		"calculate" : m => new Date(m["start_time"]).toLocaleString(),
		//"calculate" : p => new Date(p["start_time"]).toISOString(),
		"is_default" : true,
		"description" : "Beginning of the simulation window.",
		"is_metric" : false
	},
	{
		"id" : 2,
		"label" : "Stop Time",
		"getRaw" : m => m["stop_time"],
		"calculate" : m => new Date(m["stop_time"]).toLocaleString(),
		//"calculate" : p => new Date(p["stop_time"]).toISOString(),
		"is_default" : true,
		"description" : "End of the simulation window.",
		"is_metric" : false
	},
	{
		"id" : 3,
		"label" : "Total Return",
		"getRaw" : m => m["roi"],
		"calculate" : m => formatRoi(m["roi"], 1),
		"is_default" : true,
		"is_metric" : true,
		"long_name" : "Return on Investment",
		"short_name" : "R.O.I.",
		"description" : "Change in account value as a percentage at the end of the simulation window."
	},
	{
		"id" : 4,
		"label" : "Highest Return",
		"getRaw" : p => p["metrics"]["highest_roi"],
		"calculate" : m => formatRoi(m["highest_roi"], 1),
		"is_default" : false,
		"is_metric" : true,
		"long_name" : "Highest Account Value",
		"short_name" : "Highest R.O.I.",
		"description" : "Highest account value achieved throughout the simulation window."
	},
	{
		"id" : 5,
		"label" : "Lowest Return",
		"getRaw" : m => m["lowest_roi"],
		"calculate" : m => formatRoi(m["lowest_roi"], 1),
		"is_default" : false,
		"is_metric" : true,
		"long_name" : "Lowest Account Value",
		"short_name" : "Lowest R.O.I.",
		"description" : "Lowest account value achieved throughout the simulation window."
	},
	{
		"id" : 6,
		"label" : "Annualized Return",
		"getRaw" : m => m["annualized_roi"],
		"calculate" : m => formatRoi(m["annualized_roi"], 1),
		"is_default" : false,
		"is_metric" : true,
		"long_name" : "Annaualized R.O.I.",
		"short_name" : "Annalualized ROI",
		"description" : "The toal return, averaged over each year."
	},
	{
		"id" : 7,
		"label" : "Monthly Return",
		"getRaw" : m => m["monthly_roi"],
		"calculate" : m => formatRoi(m["monthly_roi"], 1),
		"is_default" : false,
		"is_metric" : true,
		"long_name" : "Monthly R.O.I.",
		"short_name" : "Monthly ROI",
		"description" : "The toal return, averaged over each month."
	},
	{
		"id" : 8,
		"label" : "Max Drawdown",
		"getRaw" : m => m["maximum_drawdown"],
		"calculate" : m => `${(m["maximum_drawdown"] * 100).toFixed(1)}%`,
		"is_default" : true,
		"is_metric" : true,
		"long_name" : "Maximum Drawdown",
		"short_name" : "M.D.D.",
		"description" : "Largest loss in account value experienced at any point during the simulation window."
	},
	{
		"id" : 9,
		"label" : "Total Positions",
		"getRaw" : m => m["total_positions"],
		"calculate" : m => m["total_positions"],
		"is_default" : true,
		"is_metric" : true,
		"long_name" : "Total Positions",
		"short_name" : "Total Positions",
		"description" : "Total number of positions opened during the simulation window."
	},
	{
		"id" : 10,
		"label" : "Win Rate",
		"getRaw" : m => m["win_rate"],
		"calculate" : m => `${(m["win_rate"] * 100).toFixed(2)}%`,
		"is_default" : true,
		"is_metric" : true,
		"long_name" : "Win Rate",
		"short_name" : "Win Rate",
		"description" : "The percentage of positions that were profitable."
	},
	{
		"id" : 11,
		"label" : "Win Loss Ratio",
		"getRaw" : m => m["win_loss_ratio"],
		"calculate" : m => m["win_loss_ratio"].toFixed(2),
		"is_default" : false,
		"is_metric" : true,
		"long_name" : "Win Loss Ratio",
		"short_name" : "W/L",
		"description" : "Total number of winners divided by total number of losers."
	},
	{
		"id" : 12,
		"label" : "Profit Factor",
		"getRaw" : m => m["profit_factor"],
		"calculate" : m => m["profit_factor"].toFixed(2),
		"is_default" : false,
		"is_metric" : true,
		"long_name" : "Profit Factor",
		"short_name" : "Profit Factor",
		"description" : "Lorem Ipsum"
	},
	{
		"id" : 13,
		"label" : "Number of Wins",
		"getRaw" : m => m["number_of_wins"],
		"calculate" : m => m["number_of_wins"].toFixed(2),
		"is_default" : false,
		"is_metric" : true,
		"long_name" : "Number of Wins",
		"short_name" : "Number of Wins",
		"description" : "Total number of won trades."
	},
	{
		"id" : 14,
		"label" : "Number of Losses",
		"getRaw" : m => m["number_of_losses"],
		"calculate" : m => m["number_of_losses"],
		"is_default" : false,
		"is_metric" : true,
		"long_name" : "Number of Losses",
		"short_name" : "Number of Losses",
		"description" : "Total number of lost trades."
	},
	{
		"id" : 15,
		"label" : "Positions / Month",
		"getRaw" : m => m["positions_per_month"],
		"calculate" : m => m["positions_per_month"].toFixed(2),
		"is_default" : false,
		"is_metric" : true,
		"long_name" : "Monthly Trade Rate",
		"short_name" : "Trades / Month",
		"description" : "The average number of positions opened in a 30 day period."
	},
];

export const POSITION_METRICS = [
	{
		"id" : 0,
		"label" : "Open Time",
		"getRaw" : p => p["open_time"],
		"calculate" : p => { return new Date(p["open_time"]).toLocaleString()},
		//"calculate" : p => { return new Date(p["open_time"]).toISOString()},
		"is_default" : true
	},
	{
		"id" : 1,
		"label" : "Close Time",
		"getRaw" : p => p["close_time"],
		"calculate" : p => { return new Date(p["close_time"]).toLocaleString()},
		//"calculate" : p => { return new Date(p["close_time"]).toISOString()},
		"is_default" : true
	},
	{
		"id" : 2,
		"label" : "Open Price",
		"getRaw" : p => p["open_price"],
		"calculate" : p => { return p["open_price"]},
		"is_default" : true
	},
	{
		"id" : 3,
		"label" : "Close Price",
		"getRaw" : p => p["close_price"],
		"calculate" : p => { return p["close_price"]},
		"is_default" : true
	},
	{
		"id" : 4,
		"label" : "Long or Short",
		"getRaw" : p => { return p["is_long"] ? 1 : 0},
		"calculate" : p => { return p["is_long"] ? "Long" : "Short"},
		"is_default" : true
	},
	{
		"id" : 5,
		"label" : "Win or Loss",
		"getRaw" : p => {
			if (p["is_long"]) {
				return (p["open_price"] < p["close_price"]) ? 1 : 0;
			} else {
				return (p["open_price"] > p["close_price"]) ? 1 : 0;
			}
		},
		"calculate" : p => {
			if (p["is_long"]) {
				return (p["open_price"] < p["close_price"]) ? "Win" : "Loss";
			} else {
				return (p["open_price"] > p["close_price"]) ? "Win" : "Loss";
			}
		},
		"is_default" : true
	},
	{
		"id" : 6,
		"label" : "Price Delta",
		"getRaw" : p => { return p["close_price"] - p["open_price"]; },
		"calculate" : p => {
			return (p["close_price"] - p["open_price"]).toFixed(5);
		},
		"is_default" : false,
		"has_distribution" : true
	},
	{
		"id" : 7,
		"label" : "Pip Delta",
		"getRaw" : p => { return p["close_price"] - p["open_price"]; },
		"calculate" : p => {
			const d = p["close_price"] - p["open_price"];
			return (d * PIP_FACTOR).toFixed(1);
		},
		"is_default" : false
	},
	{
		"id" : 8,
		"label" : "Duration",
		"getRaw" : p => { return p["close_time"] - p["open_time"]; },
		"calculate" : p => {
			const d = p["close_time"] - p["open_time"];
			return msToDuration(d);
		},
		"is_default" : false
	},
];

/* This algorithm might finally be right, but should be moved to python...
const calculateMaxDrawdown = arr => {
	let accum_roi = 1.0;
	let lowest_roi = 1.0;

	let has_peaked = false, has_troughed = true;


	let drawdowns = [];
	let peak = null, max_dd = null;

	// step 1, find lowest point in roi
	for (let i in arr) {
		let p = arr[i];
		let delta = p.close_price - p.open_price;
		let invert = p.is_long ? 1.0 : -1.0;
		let roi = 1.0 + ((delta / p.open_price) * invert);
		accum_roi *= roi;
		
		// Update peak if necessary
		if (!peak || accum_roi > peak) {
			peak = accum_roi;
		}

		// Set current Drawdown.
		const dd = (peak - accum_roi) / peak;

		// Check for new max drawdown
		if (max_dd || dd > max_dd) max_dd = dd;
	}

	return max_dd;
}
*/

/**
 * Metrics calculation methods.
 */
export const roiFromPos = (p) => {
	let delta = p.close_price - p.open_price;
	let invert = p.is_long ? 1.0 : -1.0;
	return (delta / p.open_price) * invert;
}

export const accumRoiFromPos = (pos) => {
	const { positions } = this.state;

	let arr = [ ...positions ];

	let accum_roi = 1.0;
	for (let i in arr) {
		let p = arr[i];
		if (p.open_time > pos.open_time) break;

		let delta = p.close_price - p.open_price;
		let invert = p.is_long ? 1.0 : -1.0;
		let roi = 1.0 + ((delta / p.open_price) * invert);
		accum_roi *= roi;
	}

	return accum_roi - 1.0;
}

export const calculateDistribution = (position_data, pm) => {
	const arr = position_data.map(p => pm.calculate(p));
	const sarr = arr.sort();

	let sum = 0.0;
	for (const v of arr) sum += v;

	const q1 = parseInt(arr.length * 0.25);
	const q2 = parseInt(arr.length * 0.50);
	const q3 = parseInt(arr.length * 0.75);

	return {
		"id" : pm["id"],
		"label" : pm["label"],
		"mean" : sum / arr.length,
		"min" : sarr[0],
		"max" : sarr[arr.length - 1],
		"q1" : sarr[q1],
		"median" : sarr[q2],
		"q3" : sarr[q3]
	};
};


