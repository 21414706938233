import React from "react";
/*import PropTypes from "prop-types";*/
import { AccountContext } from "../../context/AccountContext.js";

import log from "loglevel";
import { Box, Checkbox, Typography } from "@mui/material";

import {
	EmbeddedCheckoutProvider, EmbeddedCheckout
} from "@stripe/react-stripe-js";

import {
	PRICE_PLANS, STRIPE_INSTANCE, fetchClientSecret
} from "../../lib/StripeManager.js";

class Checkout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"dropin_css_id" : "dropin-container-checkout",
			"stripe_session_secret" : null,
			"selected_plan" : PRICE_PLANS[0]["plan_id"],
			"built_stripe" : false,
			"enable_autopay" : false,
		};

		this.handlePlanSelect = this.handlePlanSelect.bind(this);
		this.refreshStripeSecret = this.refreshStripeSecret.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {}

	componentDidMount() {
		this.refreshStripeSecret();
	}

	render() {
		const {
			built_stripe, dropin_css_id, selected_plan, enable_autopay,
			stripe_session_secret
		} = this.state;

		const price_plan =  PRICE_PLANS.filter(p => p["plan_id"] === selected_plan)[0];
		const stripe_opts = {
			"clientSecret" : stripe_session_secret
		};

		return (
			<Box>
				<Box
					className="flex-col-space-between"
					sx={{ "margin" : "20px" }}
				>
					<Typography
						variant="h2"
						color="secondary"
					>{price_plan["label"]}: {price_plan["price"]}</Typography>
					{/*
					<Select
						size="small"
						sx={{
							"color" : "font.main"
						}}
						value={selected_plan}
						onChange={this.handlePlanSelect}
					>
						{
							BT_PLANS.filter(po => !po.plan_id.includes("-recurring")).map(po => {
								return (
									<MenuItem
										key={po.plan_id}
										value={po.plan_id}
									>
										{po.label} - {po.price}
									</MenuItem>
								);
							})
						}
					</Select>
					*/}

					<Box className="flex-row-center">
						<Checkbox
							checked={enable_autopay}
							onChange={evt => {
								this.setState({
									"enable_autopay" : evt.target.checked
								}, this.refreshStripeSecret);
							}}
						/>
						<Typography color="font.main">Enable Autopay</Typography>
					</Box>
				</Box>

				{
					built_stripe && 
					<div id={dropin_css_id}>
						<EmbeddedCheckoutProvider
							stripe={STRIPE_INSTANCE}
							options={stripe_opts}
						>
							<EmbeddedCheckout />
						</EmbeddedCheckoutProvider>
					</div>
				}
			</Box>
		);
	}

	handlePlanSelect(evt) {
		const { value } = evt.target;

		this.setState({"selected_plan" : value});
	}

	refreshStripeSecret() {
		const { enable_autopay } = this.state;

		this.setState({ "built_stripe" : false });

		fetchClientSecret(enable_autopay, (err, token) => {
			if (err) {
				log.error(err);
				return;
			}

			this.setState({"stripe_session_secret" : token, "built_stripe" : true});
		});
	}

}

export default Checkout;
