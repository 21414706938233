import React from "react";
import PropTypes from "prop-types";
import { Box, Divider } from "@mui/material";
import { StrategyContext } from "../../../context/StrategyContext.js";
import SimulationParameters from "../SimulationParameters.js";
import TemplateEditor from "./TemplateEditor.js";

import "../../../css/template.css";

class TemplateDesigner extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static contextType = StrategyContext;
	static propTypes = {
		"template" : PropTypes.object.isRequired,
		"onChange" : PropTypes.func.isRequired,
		"is_disabled" : PropTypes.bool
	};

	render() {
		const { selected_leaf } = this.context;
		const { is_disabled, template, onChange } = this.props;

		return (
			<Box className="template-designer">
				<SimulationParameters
					group_id={selected_leaf["id"]}
					is_disabled={is_disabled}
				/>

				<Divider sx={{ "width" : "100%", "marginBottom" : "30px" }} />

				<TemplateEditor
					template={template}
					is_disabled={false} /* Not the same has is_disabled from this component. */
					onChange={onChange}
				/>
			</Box>
		);
	}

}

export default TemplateDesigner;
