import React from "react";
import PropTypes from "prop-types";

import log from "loglevel";

import { Box, Modal, Stepper, Step, StepLabel, StepContent, Typography, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import UpdatePassword from "./UpdatePassword.js";
import SendVerificationCode from "./SendVerificationCode.js";

class ResetPasswordModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"email" : null,
			"new_password" : null,
			"current_step" : 0,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	static propTypes = {
		"is_open" : PropTypes.bool.isRequired,
		"onClose" : PropTypes.func.isRequired
	};

	render() {
		const { is_open } = this.props;
		const { current_step, forgotten_account_id } = this.state;

		return (
			<Modal
				open={is_open}
				onClose={this.handleClose}
			>
				<Box
					className="center-overlay-modal"
					sx={{
						"backgroundColor" : "background.paper",
						"padding" : "30px",
						"width" : "fit-content",
						"borderWidth" : "2px",
						"borderStyle" : "solid",
						"borderColor" : "secondary.main"
					}}
				>
					<CloseIcon
						sx={{ "position" : "absolute", "top" : "5px", "right" : "5px"}}
						className="reset-password-close-icon"
						onClick={this.handleClose}
					/>

					<Typography color="font.main" variant="subtitle1" sx={{"textAlign" : "center"}}>Reset Password</Typography>
					<Divider orientation="horizontal" />

					<Stepper
						activeStep={current_step}
						orientation="vertical"
					>
						<Step key={"label-1"}>
							<StepLabel>
								<Typography color="font.main">
									Send Verification Code
								</Typography>
							</StepLabel>
							<StepContent>
								<SendVerificationCode
									onSend={(account_id) => {
										this.setState({ "current_step" : 1, "forgotten_account_id" : account_id });
									}}
								/>
							</StepContent>
						</Step>
						<Step key={"label-2"}>
							<StepLabel>
								<Typography color="font.main">
									Update Password
								</Typography>
							</StepLabel>
							<StepContent>
								<UpdatePassword
									is_forgot={true}
									account_id={forgotten_account_id}
									onSubmit={() => {
										log.debug("Password update complete");
										this.handleClose(null, null);
									}}
								/>
							</StepContent>
						</Step>
					</Stepper>
				</Box>
			</Modal>
		);
	}

	handleClose(evt, reason) {
		const { onClose } = this.props;

		if (reason === "backdropClick") {
			evt.preventDefault();
			return;
		}

		this.setState({
			"email" : null,
			"new_password" : null,
			"current_step" : 0
		}, onClose);
	}

	handleChange(evt) {
		const { name, value } = evt.target;
		this.setState({[name] : value === "" ? null : value, "bad_login" : false});
	}
}

export default ResetPasswordModal;
