import React from "react";
import PropTypes from "prop-types";

import log from "loglevel";

import {
	Box, Checkbox, Typography, Paper, Grid, MenuItem, Select
} from "@mui/material";

import { StrategyContext } from "../../../context/StrategyContext.js";
import { msToDuration } from "../../../lib/duration-ops.js";

import StrategyCharts from "../../charts/StrategyCharts.js";

class AssembleCharts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"selected_granularity" : null,
		};
		this.genCharts = this.genCharts.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"unique_granularities" : PropTypes.array.isRequired,
		"assembled_data" : PropTypes.object.isRequired,
		"metrics" : PropTypes.object.isRequired,
	};

	render() {
		const { unique_granularities } = this.props;
		const { selected_granularity } = this.state;

		const sarr = unique_granularities.sort();

		return (
			<Box className="assemble-charts">
				<Box className="flex-row-space-between">
					<Typography color="font.main" variant="h2">Engine Insights</Typography>

					<Box className="flex-row-center">
						<Box
							className="flex-row-center"
							sx={{"marginRight" : "30px"}}
						>
							<Typography
								color="font.main"
								variant="body2"
							>Show All</Typography>

							<Checkbox
								defaultChecked={true}
								onChange={evt => {
									let is_checked = evt.target.checked;
									this.setState({
										"selected_granularity" : is_checked ? null : sarr[0]
									});
								}}
							/>
						</Box>

						<Select
							defaultValue={sarr[0]}
							onChange={evt => {
								this.setState({ "selected_granularity" : evt.target.value});
							}}
							size="small"
							sx={{"color":"font.main"}}
							disabled={!selected_granularity}
						>
							{
								sarr.map(gran => {
									const s = msToDuration(gran);
									return (
										<MenuItem
											key={gran}
											value={gran}
											sx={{"color":"font.main"}}
										>{s}</MenuItem>
									);
								})
							}
						</Select>
					</Box>
				</Box>
				<Box
					sx={{
						"display" : "flex",
						"flexDirection" : "column",
					}}
				>
					{ this.genCharts() }
				</Box>
			</Box>
		);
	}

	/**
	 * Based on loaded simulation data, generates the JSX for each of the strategy charts.
	 */
	genCharts() {
		const { unique_granularities, assembled_data } = this.props;
		const { selected_granularity } = this.state;
		const chart_width = 500;
		const vw = window.innerWidth;
		const width_ratio = 0.58;

		// Apply selected granularity filter if necessary.
		let sarr = unique_granularities.sort();
		if (selected_granularity) sarr = sarr.filter(g => g === selected_granularity);

		return sarr.map(gran => {
			let entrances = assembled_data.entrance_signals.filter(s => s.granularity === gran);
			let exits = assembled_data.exit_signals.filter(s => s.granularity === gran);
			let sigs = entrances.concat(exits);

			// 			let overlayed_indicators = Object.keys(indicator_pool).filter(k => indicator_pool[k].is_overlay);
			let price_data = sigs.filter(s => s.is_overlay).map(s => s.csv_data);
			let indicator_data = sigs;
			// let indicator_data = sigs.map(s => s.csv_data);
			let distinct_graphs = Array.from(new Set(indicator_data.map(indi => `${indi.indicator_title}-${indi.parameters}`)));

			const n_graphs = distinct_graphs.length + 1;
			const do_center_graphs = (n_graphs * chart_width) <= (vw * width_ratio)
			log.debug({distinct_graphs, chart_width, vw, width_ratio, do_center_graphs});

			const grid_sx = {
				"margin" : "0px 10px 0px 10px",
				"width" : "fit-content"
			};

			// Each one of these inner containers represents a row charts sharing a granularity.
			return (
				<Box
					className="chart-container"
					key={gran}
					component={Paper}
				>
					<Box
						className="flex-row-center"
						sx={{
							"margin" : "0px 10px 0px 10px",
							"justifyContent" : do_center_graphs ? "space-around" : "flex-start",
						}}
					>
						{/* Each row has one chart for candle data + overlay indicators. */}
						<Box
							sx={grid_sx}
						>
							<Typography color="font.main" variant="body2">Price - Granularity {msToDuration(gran)}</Typography>
							<StrategyCharts // Charts the position and any overlay indicators.
								chart_id={`price-chart-${gran}`}
								price_data={price_data.length ? price_data : indicator_data.map(s => s.csv_data) }
								only_price={price_data.length === 0}
							/>
						</Box>

						{/* Plots non-price data. */}
						{
							Array.from(distinct_graphs).map((k, index) => {
								let a = indicator_data.filter(indi => k === `${indi.indicator_title}-${indi.parameters}`)[0];
								if (!a || !a.csv_data) return (<Grid item />);

								let params = a.parameters.replaceAll("-",", ");
								params = params.substring(0, params.length - 7);

								return (
									<Box
										key={`indicator-chart-${gran}-${index}`}
										sx={grid_sx}
									>
										<Typography color="font.main" variant="body2">{a.title} {params}</Typography>
										<StrategyCharts // Charts the position and any overlay indicators.
												chart_id={`indicator-chart-${gran}-${index}`}
												indicator_data={a.csv_data}
										 />
									</Box>
								);
							})
						}
					</Box>
				</Box>
			);
		})
	}


}

export default AssembleCharts;
