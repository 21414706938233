import React from "react";
// import PropTypes from "prop-types";
// import log from "loglevel";

import {
	Box, Container, Paper, Button, Typography, TextField
} from "@mui/material";

import { AccountContext } from "./../context/AccountContext.js";
import { submitSupportTicket } from "./../lib/account-ops.js";

class ContactUs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"email_content" : null,
			"has_sent" : false
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {};

	render() {
		const { is_authenticated, email } = this.context;
		const { has_sent, email_content } = this.state;

		return !is_authenticated ? (<div />) : (
			<Container
				component={Paper}
				className="flex-col-center"
				sx={{
					"padding" : "30px",
					"margin" : "60px",
					"maxWidth" : "100% !important",
				}}
			>
				<Typography
					variant="h2"
					color="font.main"
				>Contact Us</Typography>

				<Typography
					variant="subtitle1"
					color="font.main"
				>We will get back to you as soon as possible.</Typography>

				{
					has_sent ? (
						<Box
							className="flex-col-center"
							sx={{ "margin" : "40px" }}
						>
							<Typography
								variant="subtitle1"
								color="secondary"
							>Thanks for reaching out!</Typography>

							<Box className="flex-row-center">
								<Typography
									variant="body1"
									color="secondary"
								>
									Once we have reviewed your issue, we will respond to you at
									&#20;
								</Typography>
								<Typography
									variant="body1"
									color="primary"
								>{email}</Typography>
							</Box>
						</Box>
					) : (
						<TextField
							multiline
							fullWidth
							focused
							rows={6}
							color="primary"
							label={"Please describe your problem"}
							onChange={this.handleChange}
							sx={{"margin" : "40px"}}
							InputProps={{"sx" : {"color" : "font.main"}}}
							InputLabelProps={{"sx" : {"typography" : "body1"}}}
						/>
					)
				}

				<Button
					variant="contained"
					color="primary"
					sx={{
						"color" : "font.button",
						"marginTop" : "20px"
					}}
					disabled={has_sent || !email_content || email_content.length < 32}
					onClick={this.handleSubmit}
				>Submit</Button>
			</Container>
		);
	}

	handleSubmit() {
		const { email, setContext } = this.context;
		const { email_content } = this.state;

		const footer = "\r\n\r\n Sent By " + email;

		submitSupportTicket(email_content + footer, err => {
			let msg = err ? "Failed to submit support ticket." : "Submitted Support Ticket"
			setContext({
				"snackbar_sev" : err ? "error" : "success",
				"snackbar_msg" : msg
			}, () => {
				this.setState({"has_sent" : true});
			});
		});
	}

	handleChange(evt) {
		this.setState({"email_content" : evt.target.value});
	}
}

export default ContactUs;

