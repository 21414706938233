


/**
 * Regex tests
 */


/**
 * Compares the provided value to a regular expression.
 *
 * @param {*} value
 * @returns true if the provided value matches the regex, and false otherwise.
 */
export const isValidEmail = (value) => {
	let expr = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
	return expr.test(value);
}

/**
 *
 * @param {*} value
 * @returns true if the provided value matches the regex, and false otherwise.
 */
export const isValidPassword = (value) => {
	const has_len = /^.{8,32}$/.test(value);
	const has_chars = /^[a-zA-Z0-9'~!@#$%&*_+=^]*$/.test(value);
	const has_lower = /[a-z]/.test(value);
	const has_upper = /[A-Z]/.test(value);
	const has_number = /[0-9]/.test(value);
	const has_special = /['~!@#$%&*_+=^]/.test(value);

	const is_valid = has_len && has_chars && has_lower && has_upper && has_number && has_special;
	return { is_valid, has_len, has_chars, has_lower, has_upper, has_number, has_special, "special_characters" : "'~!@#$%&*_+=^" }
}

/**
 *
 * @param {*} value
 * @returns true if the provided value matches the regex, and false otherwise.
 */
export const isValidUsername = (value) => {
	const valid_chars = /^[a-zA-Z0-9_]{6,32}$/.test(value);

	// const word_blacklist = [ "damn" ];
	const has_bad_word = false;

	const is_valid = valid_chars && !has_bad_word;

	return { is_valid, has_bad_word, valid_chars };
}

export const isValidVerificationCode = (value) => {
	const expr = /^[a-zA-Z0-9]{6}$/;
	return expr.test(value);
}

/**
 *
 * @param {*} value
 * @returns true if the provided value matches the regex, and false otherwise.
 */
export const isValidPhoneNumber = (value) => {
	// Credit: https://regexlib.com/REDetails.aspx?regexp_id=45
	const expr = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

	return expr.test(value);
}

export const isValidFloat = (value) => {
	const expr = /^[-]{0,1}[0-9.]*$/;

	return expr.test(value);
}

export const formatMoney = (x) => {
	if (!x) return null;

	const opts = { "style" : "currency", "currency": "USD" };
	const fmt = new Intl.NumberFormat("en-US", opts);

	return fmt.format(x);
}
