import React from "react"
import PropTypes from "prop-types";

// import log from "loglevel";

import { TextField } from "@mui/material";
import { msToDuration, isDuration, durationToMs } from "../../lib/duration-ops.js"

class DurationInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"value" : "0s",
			"ms_value" : null
		};

		this.handleChange = this.handleChange.bind(this);
	}

	static propTypes = {
		"class_name" : PropTypes.string,
		"label" : PropTypes.string,
		"onChange" : PropTypes.func.isRequired,
		"value" : PropTypes.number.isRequired,
		"auto_focus" : PropTypes.bool,
		"boundaries" : PropTypes.object,
		"is_disabled" : PropTypes.bool,
		"sx" : PropTypes.object,
		"inputProps" : PropTypes.object,
	};

	componentDidMount() {
		const { value } = this.props;

		this.setState({
			"value" : msToDuration(value),
			"ms_value" : value
		});
	}

	componentDidUpdate(prevProps) {
		const { "value" : old_value } = prevProps;
		const { "value" : new_value } = this.props;

		// Detects if the parent provided a new value.
		if (old_value !== new_value) {
			const d = msToDuration(new_value);
			this.setState({ "value" : d, "ms_value" : new_value });
		}
	}

	render() {
		const {
			class_name, auto_focus, is_disabled, label, sx, inputProps
		} = this.props;
		const { value, ms_value } = this.state;

		const is_valid = isDuration(value) && !!ms_value;

		return (
			<TextField
				label={label}
				disabled={is_disabled}
				color={is_valid ? "success" : "warning"}
				error={!is_valid}
				className={class_name}
				autoFocus={auto_focus}
				value={value}
				onChange={this.handleChange}
				size="small"
				sx={!!sx ? sx : undefined}
				inputProps={!!inputProps ? inputProps : undefined}
				InputProps={{"sx" : {"typography" : "body2", "color" : "font.main"}}}
				InputLabelProps={{"sx" : {"typography" : "body2", "color" : "font.main"}}} />
		);
	}

	handleChange(evt) {
		const { onChange } = this.props;
		const { value } = evt.target;
		const old_ms_value = this.state.ms_value;
		let new_ms_value = null;
		let r = 0;

		if (isDuration(value)) {
			new_ms_value = durationToMs(value);
			r = new_ms_value % (1000 * 60 * 10);
			new_ms_value -= r;
		} else {
			// TODO: Show warning arround input?
			// log.debug("Not a duration.");
		}

		this.setState({
			"value" : new_ms_value ? msToDuration(new_ms_value) : value,
			"ms_value" : new_ms_value || old_ms_value
		}, () => {
			if (new_ms_value) onChange(new_ms_value + r);
		});
	}
}

export default DurationInput;

