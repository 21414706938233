import React from "react";
import PropTypes from "prop-types";
import {
	Button, Box, TextField, Typography
} from "@mui/material";

import log from "loglevel";

import { isValidPassword, isValidVerificationCode } from "../../lib/string-validation.js";
import { updatePasswordWithCode, updatePasswordWithSession } from "../../lib/account-ops.js";

import { AccountContext } from "../../context/AccountContext";


/**
 * This component has two modes, toggled by is_forgot prop.
 * if is_forgot is true, then the user must enter a value veririfcation code instead of their old password.
 *
 */
class UpdatePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"is_open" : false,
			"old_password" : null,
			"verification_code" : null,
			"new_password" : null,
			"new_password_confirm" : null,
			"bad_old_password" : false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"onSubmit" : PropTypes.func.isRequired,
		"is_forgot" : PropTypes.bool,
		"account_id" : PropTypes.number
	};

	render() {
		const { is_forgot } = this.props;

		const {
			is_open, new_password, verification_code, new_password_confirm,
			bad_old_password, old_password
		} = this.state;

		const is_valid_password = isValidPassword(new_password);
		const is_valid_password_confirm = new_password_confirm === new_password;


		const is_valid_auth = is_forgot ? (!verification_code || isValidVerificationCode(verification_code)) : (old_password && !bad_old_password);
		const auth_helper_text = is_valid_auth ? "" : (is_forgot ? "Invalid Code" : "Password not correct");

		const password_props = {
			"type" : "password",
			"onChange" : this.handleChange,
			"size" : "small",
			"InputProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"InputLabelProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"sx" : {"margin" : "10px 0px 10px 0px"}
		};

		return (
			<Box className="flex-col-center">
				{
					!is_forgot &&
					<Typography
						className="update-toggle"
						onClick={() => {
							this.setState({ "is_open" : !is_open });
						}}
						variant="body2"
						color="primary"
						sx={{
							"&:hover" : {
								"color" : "secondary.main"
							}
						}}
						>Change Password</Typography>
				}

				{
					(is_open || is_forgot) &&
					<Box className="flex-col-center" >
						<TextField
							{ ...password_props }
							name={is_forgot ? "verification_code" : "old_password"}
							label={is_forgot ? "Verification Code" : "Old Password"}
							error={!is_valid_auth}
							helperText={auth_helper_text} />

						<TextField
							{ ...password_props }
							name="new_password"
							label="New Password"
							error={new_password && !is_valid_password}
							helperText={(!new_password || is_valid_password) ? "" : "Invalid Password"} />

						<TextField
							{ ...password_props }
							name="new_password_confirm"
							label="Confirm New Password"
							error={new_password_confirm && !is_valid_password_confirm}
							helperText={(!new_password_confirm || is_valid_password_confirm) ? "" : "Passwords Must Match"} />

						<Button
							onClick={this.handleSubmit}
							variant="contained"
							color="primary"
							sx={{"color":"font.button"}}
							disabled={!is_valid_password || !is_valid_password_confirm || !is_valid_auth}
							>Change</Button>
					</Box>
				}


			</Box>
		);
	}

	/**
	 * Updates state for each new update to the form.
	 * 
	 * @param {*} evt The event from the field that was updated. 
	 */
	 handleChange(evt) {
		const { bad_old_password } = this.state;
		const { name, value } = evt.target;

		const changed_old = name === "old_password";

		this.setState({
			[name] : value,
			"bad_old_password" : (bad_old_password && changed_old) ? false : bad_old_password
		});
	}

	handleSubmit() {
		const { setContext } = this.context;
		const { is_forgot, account_id, onSubmit } = this.props;
		const {
			verification_code, old_password,
			new_password, new_password_confirm
		} = this.state;

		if (is_forgot && !isValidVerificationCode(verification_code)) return;
		else if (!isValidPassword(new_password)) return;
		else if (new_password_confirm !== new_password) return;

		if (is_forgot) {
			updatePasswordWithCode(new_password, account_id, verification_code, (err) => {
				if (err) {
					if (err.response) setContext({ "snackbar_msg" : "Incorrect Verification Code", "snackbar_sev" : "error"});
					else setContext({ "snackbar_msg" : "Password Reset Failed", "snackbar_sev" : "error"});
					return log.debug(err);
				}

				setContext({ "snackbar_msg" : "Password Reset Successfully", "snackbar_sev" : "success"});
				this.setState({
					"is_open" : false,
					"old_password" : null,
					"verification_code" : null,
					"new_password" : null,
					"new_password_confirm" : null,
					"bad_old_password" : false,
					"bad_verification_code" : false
				}, onSubmit);
			});
		} else {
			updatePasswordWithSession(old_password, new_password, (err) => {
				if (err) {
					setContext({
						"snackbar_msg" : "Password Update Failed",
						"snackbar_sev" : "error"
					});
					this.setState({ "bad_old_password" : true });
					return log.debug(err);
				}

				setContext({ "snackbar_msg" : "Password Updated Successfully", "snackbar_sev" : "success"});
				this.setState({
					"is_open" : false,
					"old_password" : null,
					"verification_code" : null,
					"new_password" : null,
					"new_password_confirm" : null,
					"bad_old_password" : false,
					"bad_verification_code" : false
				}, onSubmit);
			});
		}
	}
}

export default UpdatePassword;
