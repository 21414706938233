import React from "react";
import PropTypes from "prop-types";

import {
	Box, Button, Typography, TextField, Tooltip, IconButton, Divider
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import log from "loglevel";

import { StrategyContext } from "../../context/StrategyContext.js";
import TemplateDesigner from "./template-designer/TemplateDesigner.js";
import CenterModal from "../CenterModal.js";
import StrategyTable from "./StrategyTable.js";
import DashboardStart from "./DashboardStart.js";

import {
	updateTemplate, deleteGroup, getGroupStrategies, AUTOSAVE_TIMEOUT_MS
} from "../../lib/strategy-ops.js";

class GroupView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"new_group_name" : null,
			"is_renaming" : false,
			"autosave_timeout" : null,
			"is_autosaving" : false,
			"quick_unmount" : false,
			"show_delete" : false,
			"page" : 1,
			"max_pages" : 0,
			"total_strategies" : 0,
		};

		this.submitTemplate = this.submitTemplate.bind(this);
		this.deleteGroup = this.deleteGroup.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.cleanTemplateField = this.cleanTemplateField.bind(this);
		this.cleanTemplate = this.cleanTemplate.bind(this);
		this.loadGroup = this.loadGroup.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"match" : PropTypes.object.isRequired,
		"history" : PropTypes.object.isRequired,
		"location" : PropTypes.object.isRequired
	};

	componentDidMount() {
		this.loadGroup();

		window.addEventListener('beforeunload', (e) => {
			const { unsaved_changes } = this.context;

			// Check if any of the input fields are filled
			if (unsaved_changes) {
				// Cancel the event and show alert that
				// the unsaved changes would be lost
				// The logic is weird, but "beforeunload" events have a default action of "unloading". So, by preventDefault() -ing the beforeunload event, you are preventing it from unloading and thus displaying the dialog.
				e.preventDefault();
			}
		});

	}

	componentDidUpdate(prev_props) {
		const old_group = prev_props.match.params["group_id"];
		const new_group = this.props.match.params["group_id"];

		if (new_group !== old_group) {
			this.loadGroup(() => {
				/* TODO: Why is this mount/unmount needed? */
				this.setState({ "quick_unmount" : true}, () => {
					this.setState({ "quick_unmount" : false });
				});
			});
		}
	}

	render() {
		const {
			selected_leaf, selected_branch, setContext, unsaved_changes, strategies
		} = this.context;
		const {
			is_renaming, new_group_name, is_autosaving, quick_unmount,
			show_delete, total_strategies, page, max_pages
		} = this.state;

		const is_ready = !!selected_leaf && !!selected_branch;
		if (!is_ready || quick_unmount) return <div />;

		// const selected_custom_group = selected_branch === "group" && selected_leaf["type"] === "custom";
		const selected_template_group = selected_branch === "group" && selected_leaf["type"] === "template";

		return (
			<Box className="group-view-cont">
					<Box className="group-rename-box">
						{
							is_renaming ? (
								<Box className="flex-row-space-between">
									<TextField
										type="text"
										name="new_group_name"
										label={selected_leaf.title}
										size="small"
										onChange={this.handleUpdate}
										error={false}
										helperText="" />

									<Tooltip title="Save">
										<span>
											<IconButton
												onClick={() => {
													this.submitTemplate(new_group_name);
												}}
											>
												<SaveIcon
													color="success"
													fontSize="medium"
												/>
											</IconButton>
										</span>
									</Tooltip>
									<Tooltip title="Cancel">
										<span>
											<IconButton
												onClick={() =>{
													this.setState({
														"is_renaming":false,
														"new_group_name" : null
													})
												}}
											>
												<ClearIcon
													color="error"
													fontSize="medium"
												/>
											</IconButton>
										</span>
									</Tooltip>
								</Box>
							) : (
								<Box className="flex-row-space-between">
									<Typography color="font.main" variant="h1">{selected_leaf.title}</Typography>
									<Tooltip title="Rename">
										<span>
										<IconButton onClick={()=>{this.setState({"is_renaming":true})}}>
											<EditIcon color="primary" fontSize="medium"/>
										</IconButton>
										</span>
									</Tooltip>
									<Tooltip title="Delete Group">
										<span>
										<IconButton
											onClick={() => {
												this.setState({
													"show_delete" : true
												});
											}}
										>
											<DeleteIcon color="error" fontSize="medium"/>
										</IconButton>
										</span>
									</Tooltip>
								</Box>
							)
						}
					</Box>

					{
						(unsaved_changes || is_autosaving) &&
						<Box className="flex-row-center">
							<Typography
								sx={{"display":"flex"}}
								variant="body2"
								color="error"
							>
								{ unsaved_changes ? "You have unsaved changes." : "Autosaving..."}
							</Typography>

							<SaveIcon color="success" fontSize="medium"/>
						</Box>
					}

				<Divider className="group-view-divider" />

				{
					selected_template_group &&
					<TemplateDesigner
						is_disabled={unsaved_changes || is_autosaving}
						template={selected_leaf ? selected_leaf["template"] : null}
						onChange={(template) => {
							let { autosave_timeout } = this.state;
							if (autosave_timeout) clearTimeout(autosave_timeout);

							// TODO: flag this as having been called since the last onSave()
							autosave_timeout = setTimeout(() => {
								this.setState({ "is_autosaving" : true }, this.submitTemplate);
							}, AUTOSAVE_TIMEOUT_MS);

							this.setState({autosave_timeout});
							setContext({
								"selected_leaf" : {...selected_leaf, template},
								"unsaved_changes" : true
							});
						}}
					/>
				}

				{
					!!total_strategies && <>
					<Divider className="group-view-divider" />
					<StrategyTable
						total_strategies={total_strategies}
						page={page}
						max_pages={max_pages}
						strategies={strategies[`group-${selected_leaf['id']}`]}
						onPagination={(new_page) => {
							this.setState({"page" : new_page}, this.loadGroup);
						}}
					/>
					</>
				}

				{
					(!total_strategies && !selected_template_group) &&
					<DashboardStart topic="strategy" />
				}

				{
					show_delete &&
					<CenterModal
						is_open={show_delete}
						title="Delete Group"
						onClose={() => {
							this.setState({"show_delete" : false});
						}}
					>
						<Typography
							color="font.main"
							variant="subtitle1"
						>Delete your group?</Typography>

						<Typography
							color="font.main"
							variant="body1"
						>
							This action is cannot be undone. All of your group settings, strategies, and simulations under this group will be lost.
						</Typography>

						<Button
							variant="contained"
							color="error"
							sx={{
								"margin" : "20px",
								"color" : "font.button"
							}}
							onClick={this.deleteGroup}
						>Confirm Delete</Button>
					</CenterModal>
				}
			</Box>
		);
	}

	submitTemplate(new_group_name=null) {
		const { selected_leaf, groups, setContext } = this.context;
		const { id, template } = selected_leaf;
		const { autosave_timeout } = this.state;

		// Prevents the autosave feature from proceeding if the user manually saves.
		if (autosave_timeout) clearTimeout(autosave_timeout);

		// If a group name has been passed, update StrategyContext.
		if (new_group_name) {
			if (template) template["title"] = new_group_name;
			// Updates groups array as well as selected_leaf.
			for (const g of groups) {
				if (g["id"] === selected_leaf["id"]) {
					g["title"] = new_group_name;
				}
			}

			setContext({
				groups,
				"selected_leaf" : {
					...selected_leaf,
					"title" : new_group_name
				}
			});
		}

		// Sends PATCH request to persist template updates.
		const obj = template ? this.cleanTemplate(template) : {"title":new_group_name};
		updateTemplate(id, obj, (err, res) => {
			if (err) return log.error(err);

			setContext({
				"unsaved_changes" : false,
				"snackbar_msg" : "Saved Template",
				"snackbar_sev" : "success"
			});

			this.setState({
				"is_renaming" : false,
				"new_group_name" : null,
				"autosave_timeout" : null,
				"is_autosaving" : false
			});
		});
	}

	cleanTemplateField(field) {
		const key_set = Object.keys(field);

		if (key_set.includes("value")) {
			field["value"] = Number(field["value"]);
		} else if (key_set.includes("options")) {
			for (let i = 0; i < field["options"].length; i += 1) {
				field["options"][i] = Number(field["options"][i]);
			}
		} else {
			field["min"] = Number(field["min"]);
			field["max"] = Number(field["max"]);
			field["step"] = Number(field["step"]);
		}

		return field;
	}

	cleanTemplate(template) {
		log.debug({template});
		template["stop_loss"] = this.cleanTemplateField(template["stop_loss"]);
		template["take_profit"] = this.cleanTemplateField(template["take_profit"]);

		let signals = template["entrance_signals"];
		for (let i = 0; i < signals.length; i += 1) {
			const key_set = Object.keys(signals[i].parameters);
			for (let j = 0; j < key_set.length; j += 1) {
				const k = key_set[j];
				const obj = signals[i]["parameters"][k];
				log.debug({i, j, k, obj});
				template["entrance_signals"][i]["parameters"][k] = this.cleanTemplateField(obj);
			}
		}

		signals = template["exit_signals"];
		for (let i = 0; i < signals.length; i += 1) {
			const key_set = Object.keys(signals[i].parameters);
			for (let j = 0; j < key_set.length; j += 1) {
				const k = key_set[j];
				const obj = signals[i]["parameters"][k];
				log.debug({i, j, k, obj});
				template["exit_signals"][i]["parameters"][k] = this.cleanTemplateField(obj);
			}
		}

		return template;
	}

	deleteGroup() {
		const { selected_leaf, groups, setContext } = this.context;

		deleteGroup(selected_leaf["id"], (err, res) => {
			if (err) return log.debug(err);

			setContext({
				"selected_branch" : null,
				"selected_leaf" : null,
				"groups" : groups.filter(g => g.id !== selected_leaf.id)
			});
		});
	}

	handleUpdate(evt) {
		const { name, value } = evt.target;

		if (name !== "new_group_name") return;

		this.setState({ [name] : value });
	}

	/* TODO: This is another good candidate of something that should be pulled into its own context operation */
	loadGroup(cb=null) {
		const { strategies, strat_page_size, setContext } = this.context;
		const { page } = this.state;
		const group_id = this.props.match.params["group_id"];
		const gk = `group-${group_id}`;

		setContext({
			"is_loading" : true,
			"loading_msg" : "Loading strategies..."
		});

		getGroupStrategies(group_id, page, strat_page_size, (err1, res1) => {
			if (err1) {
				if (cb) return cb(err1);
				else return log.error(err1);
			}

			this.setState({
				"total_strategies" : res1["total"],
				"max_pages" : res1["pages"],
			}, () => {
				strategies[gk] = res1.strategies;
				setContext({
					strategies,
					"current_strategy_max_pages" : res1["pages"],
					"is_loading" : false,
					"loading_msg" : null
				}, cb);
			});
		});
	}
};

export default GroupView;
