import React from "react";

import { StrategyContext } from "../../../context/StrategyContext.js";

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
	Button, Box, Typography, Grid, FormControlLabel, Checkbox,
	Tooltip, IconButton, Icon
} from "@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from "@mui/icons-material/Settings";

import CenterModal from "../../CenterModal.js";

// import {
// 	buildPositionsCsv
// } from "../../../lib/strategy-ops.js";

import { POSITION_METRICS } from "../../../lib/metric-ops.js";

class PositionTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"selected_headers" : POSITION_METRICS.filter(pm => pm["is_default"]),
			"new_selected_headers" : POSITION_METRICS.filter(pm => pm["is_default"]),
			"show_selector" : false,
			"selected_column" : null,
			"sort_direction" : false,
		};

		this.renderHeaderOptions = this.renderHeaderOptions.bind(this);

		this.setSelectedPosition = this.setSelectedPosition.bind(this);
		this.prevPosition = this.prevPosition.bind(this);
		this.nextPosition = this.nextPosition.bind(this);
		this.handleSort = this.handleSort.bind(this);
	}

	static contextType = StrategyContext;

	componentDidMount() {
		this.handleSort(POSITION_METRICS[1]);
	}

	render() {
		const { selected_position, position_data, setContext } = this.context;
		const {
			selected_headers, new_selected_headers, show_selector,
			selected_column, sort_direction
		} = this.state;

		if (!position_data) return <div />

		return (
			<Box className="position-table">
				<Box sx={{"display" : "flex", "flexDirection" : "row"}}>
					<Typography color="font.main" variant="h2">All Positions ({position_data.length})</Typography>
					<Tooltip title="Customize Columns">
						<IconButton
							onClick={() => {
								this.setState({
									"show_selector" : true
								});
							}}
						>
							<SettingsIcon />
						</IconButton>
					</Tooltip>

					{
						selected_position &&
						<Tooltip title="Previous Position">
							<IconButton onClick={this.prevPosition}>
							<ChevronLeftIcon color="primary" sx={{"typography" : "h2"}} />
							</IconButton>
						</Tooltip>
					}
					{
						selected_position &&
						<Tooltip title="Next Position">
							<IconButton onClick={this.nextPosition}>
								<ChevronRightIcon color="primary" sx={{"typography" : "h2"}} />
							</IconButton>
						</Tooltip>
					}
				</Box>

				<TableContainer component={Paper}>
					<Table sx={{}}>
						<TableHead>
							<TableRow>
								{selected_headers.map(sh => {
									return (
										<TableCell
											key={sh["id"]}
											className="position-header-cell"
											sx={{
												"color" : "font.main"
											}}
											align="left"
											onClick={() => {
												this.handleSort(sh);
											}}
										>
											<Box className="flex-row-center">
												{sh["label"]}
												{
													sh.id === selected_column &&
													<Icon
														component={
															sort_direction ?
															KeyboardArrowDownIcon
															:
															KeyboardArrowUpIcon
														}
													/>
												}
											</Box>
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>

						<TableBody>
							{/* Iteratively generates a row for each position. */}
							{position_data && position_data.map(p => {
								const is_selected = p.open_time === selected_position.open_time;

								return (
									<TableRow
										className={is_selected ? "position-row-selected" : "position-row"}
										key={p["open_time"]}
										onClick={() => {
											setContext({
												"selected_position" : p,
												"graph_start_time" : parseInt(p.open_time / 1000),
												"graph_stop_time" : parseInt(p.close_time / 1000)
											});
										}}
									>
										{selected_headers.map(sh => {
											const v = sh.calculate(p);

											return (
												<TableCell
													key={sh["id"]}
													align="center"
													sx={{
														"color" : "font.main"
													}}
												>{v}</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>

				<CenterModal
					is_open={show_selector}
					title="Select Which Headers to Display"
					onClose={() => {
						this.setState({"show_selector" : false});
					}}
				>
						<Grid
							container
							spacing={0}
							sx={{
								"width" : "100%",
								"height" : "70%",
								"display" : "flex",
								"flexDirection" : "row",
								"justifyContent" : "space-around",
								"alignItems" : "start"
							}}
						>
							{ this.renderHeaderOptions() }
						</Grid>

						<Button
							variant="contained"
							sx={{
								"color":"font.button",
								"marginTop" : "30px"
							}}
							onClick={() => {
								this.setState({
									"show_selector" : false,
									"selected_headers" : new_selected_headers
								});
							}}
						>Save</Button>
				</CenterModal>

			</Box>
		);
	}

	renderHeaderOptions() {
		const { new_selected_headers } = this.state;

		const handleCheck = (po, is_checked) => {
			if (is_checked) {
				this.setState({
					"new_selected_headers" : new_selected_headers.filter(sh => sh.id !== po.id)
				});
			} else {
				this.setState({
					"new_selected_headers" : [ ...new_selected_headers, po ]
				});
			}
		}

		return POSITION_METRICS.map(po => {
			const is_checked = new_selected_headers.map(sh => sh.id).includes(po.id);

			return (
				<Grid className="simulation-header-label" item key={po["id"]} xs={4}>
					<FormControlLabel
						label={po["label"]}
						control={
							<Checkbox
								checked={is_checked}
								onChange={e => {
									handleCheck(po, is_checked);
								}}
							/>
						}
					/>
				</Grid>
			);
		});
	}

	setSelectedPosition(idx) {
		const { position_data, setContext } = this.context;
		const p = position_data[idx];

		setContext({
			"selected_position" : p,
			"graph_start_time" : parseInt(p.open_time / 1000),
			"graph_stop_time" : parseInt(p.close_time / 1000)
		});
	}

	/**
	 * Chart navigation methods.
	 */
	prevPosition() {
		const { position_data, selected_position } = this.context;

		let idx = null;
		for (const i in position_data) {
			const p = position_data[parseInt(i)];
			idx = (parseInt(i) > 0) ? (parseInt(i) - 1) : (position_data.length - 1);
			if (p.open_time === selected_position.open_time) break;
		}

		this.setSelectedPosition(idx);
	}

	nextPosition() {
		const { position_data, selected_position } = this.context;

		let idx = null;
		for (const i in position_data) {
			const p = position_data[parseInt(i)];
			idx = (parseInt(i) < (position_data.length - 1)) ? (parseInt(i) + 1) : 0;
			if (p.open_time === selected_position.open_time) break;
		}

		this.setSelectedPosition(idx);
	}

	handleSort(ph) {
		const { position_data, setContext } = this.context;
		const { selected_column, sort_direction } = this.state;

		setContext({
			"is_loading" : true,
			"loading_msg" : `Sorting by ${ph.label}...`
		}, () => {
			setTimeout(() => {

				let new_dir = (ph.id !== selected_column) ? true : !sort_direction;
				position_data.sort((a, b) => {
					let pa = ph.getRaw(a);
					let pb = ph.getRaw(b);

					if (new_dir) return pb - pa;
					else return pa - pb;
				});

				this.setState({
					"selected_column" : ph.id,
					"sort_direction" : new_dir
				}, () => {
					setContext({
						"is_loading" : false,
						"loading_msg" : null,
						position_data
					});
				});
			}, 100);
		});
	}
}

export default PositionTable;
