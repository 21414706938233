import React from "react";
import PropTypes from "prop-types";

import log from "loglevel";

import { Box, Typography, TextField, Button } from "@mui/material";

import { isValidUsername } from "../../lib/string-validation.js";
import { updateUsername } from "../../lib/account-ops.js";

class UpdateUsername extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"new_username" : null,
			"is_open" : false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	static propTypes = {
		"onSubmit" : PropTypes.func.isRequired,
	};

	render() {
		const { new_username, is_open } = this.state;

		const is_valid = isValidUsername(new_username);

		const tf_props = {
			"type" : "text",
			"name" : "new_username",
			"label" : "New Username",
			"onChange" : this.handleChange,
			"error" : !is_valid,
			"helperText" : is_valid ? "" : "Invalid Username",
			"size" : "small",
			"InputProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"InputLabelProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"sx" : { "margin" : "20px 0px 20px 0px" }
		};

		return (
			<Box className="flex-col-center">
				<Typography
					color="primary"
					className="update-toggle"
					variant="body2"
					sx={{"&:hover" : {"color" : "secondary.main"}}}
					onClick={() => {
						this.setState({ "is_open" : !is_open });
					}}
				>Change Username</Typography>

				{
					is_open &&
					<Box className="flex-col-center">
						<TextField {...tf_props} />

						<Button
							onClick={this.handleSubmit}
							variant="contained"
							color="primary"
							disabled={!is_valid}
							sx={{"color":"font.button"}}
						>Change</Button>
					</Box>
				}
			</Box>
		);
	}

	handleChange(evt) {
		const { name, value } = evt.target;
		this.setState({[name] : value});
	}

	handleSubmit() {
		const { onSubmit } = this.props;
		const { new_username } = this.state;

		if (!isValidUsername(new_username)) return;

		updateUsername(new_username, (err, res) => {
			if (err) {
				return log.debug(err);
			}

			onSubmit();
			this.setState({ "is_open" : false, "new_username" : null }, onSubmit);
		});
	}
}

export default UpdateUsername;
