import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import log from "loglevel";

import App from './component/App.js';
import './css/index.css';

/* Set log granularity based on environment. */
const { REACT_APP_ENV } = process.env;
if (REACT_APP_ENV !== "prod") log.setLevel("debug");
else log.setLevel("info");

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
