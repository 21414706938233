import React from "react";
import PropTypes from "prop-types";

import log from "loglevel";

import {
	Box, Button, Container, Checkbox, Grid, Paper, TextField, Typography
} from "@mui/material";

import { isValidEmail, isValidUsername, isValidPassword } from "../../lib/string-validation.js";
import {
	createAccount, logIn, sendVerificationToken, updateEmail
} from "../../lib/account-ops";

import CenterModal from "../CenterModal.js";
import TermsConditions from "../TermsConditions.js";
import { AccountContext } from "../../context/AccountContext.js";

class SignupForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"email" : null,
			"username" : null,
			"password" : null,
			"password_confirm" : null,
			"duplicate_email" : false,
			"duplicate_userame" : false,
			"show_agreement" : false,
			"has_agreed" : false,
			"otp_pending" : false,
			"otp_code" : null,
			"otp_code_bad" : false,
			"otp_remaining" : null
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitOTP = this.handleSubmitOTP.bind(this);
		this.sendVerificationToken = this.sendVerificationToken.bind(this);
		this.isValidForm = this.isValidForm.bind(this);
		this.getTextFieldProps = this.getTextFieldProps.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"onSuccess" : PropTypes.func.isRequired
	};

	render() {
		const { onSuccess } = this.props;
		const {
			show_agreement, has_agreed, otp_pending, otp_code_bad, otp_remaining
		} = this.state;

		const tf_props = this.getTextFieldProps();
		const otp_modal_text = otp_remaining ?
				`Your OTP will expire in ${otp_remaining} second${otp_remaining === 1 ? "" : "s"}.`
				:
				"Your OTP has expired.";

		return (
			<Container
				component={Paper}
				className="signup-widget"
			>

				<Box>
					<Box className="flex-col-center">
						<TextField { ...tf_props["username"] } />
						<TextField { ...tf_props["email"] } />
					</Box>
					{/*}<TextField { ...tf_props["phone"] }/> TODO: Not sure a better place to put this, but it isn't required for base sign up. */}

					<Box
						className="flex-col-center"
						sx={{"margin" : "30px 0px 20px 0px"}}
					>
						<TextField { ...tf_props["password"] } />
						<TextField { ...tf_props["password_confirm"] } />
					</Box>
				</Box>


				<Box
					className="flex-row-center"
					sx={{ "&:hover" : {"cursor":"pointer"}}}
					onClick={()=>{this.setState({"show_agreement":true})}}
				>
					<Checkbox
						checked={has_agreed}
						onClick={()=>{
							if (has_agreed) this.setState({"has_agreed":false});
							else this.setState({"show_agreement":true})
						}}
					 />
					<Typography
						color="font.main"
						variant="body3"
					>
						I have read and agree to the Terms and Conditions of StratSim, Inc.
					</Typography>
				</Box>

				<Button
					disabled={!this.isValidForm() || !has_agreed}
					onClick={this.handleSubmit}
					variant="contained"
					color="primary"
					sx={{
						"display" : "flex",
						"margin" : "20px auto 0px auto",
						"color" : "font.button"
					}}
				>Create Account</Button>

				<CenterModal
					is_open={show_agreement}
					title="Terms and Conditions"
					onClose={() => {
						this.setState({"show_agreement" : false});
					}}
					sx={{
						"width" : "60vw"
					}}
				>
					<Grid
						container
						spacing={2}
						sx={{
							"overflowY" : "scroll",
							"maxHeight" : "400px",
						}}
					>
						<Grid item xs={12}>
							<TermsConditions />
						</Grid>
					</Grid>
					<Button
						variant="contained"
						sx={{
							"color":"font.button",
							"marginTop" : "30px"
						}}
						onClick={() => {
							this.setState({
								"show_agreement" : false,
								"has_agreed" : true
							});
						}}
					>
						I agree
					</Button>
				</CenterModal>

				<CenterModal
					is_open={otp_pending}
					onClose={onSuccess}
					title={"Verify Email Address"}
				>
					<Box className="flex-col-center">
						<Typography
							varaint="h2"
							color="font.main"
						>{otp_modal_text}</Typography>

						{
							(otp_remaining > 0) &&
							<TextField
								{...tf_props["verify_email"]}
								name="otp_code"
								label="Verification Code"
								error={otp_code_bad}
								helperText={otp_code_bad ? "Incorrect Verification Code" : ""} />
						}

						<Button
							onClick={otp_remaining ? this.handleSubmitOTP : this.sendVerificationToken}
							variant="contained"
							color="primary"
							sx={{"color" : "font.button", "marginTop" : "10px"}}
						>{ otp_remaining ? "Verify" : "Resubmit" }</Button>
					</Box>
				</CenterModal>
			</Container>
		)
	}

	handleChange(evt) {
		const { name, value } = evt.target;
		const { duplicate_email, duplicate_username } = this.state;

		this.setState({
			[name] : value,
			"duplicate_email" : (name === "email") ? false : duplicate_email,  
			"duplicate_username" : (name === "username") ? false : duplicate_username,
		});
	}

	handleSubmit() {
		const { setContext } = this.context;
		const { email, username, password } = this.state;

		createAccount(email, username, password, (err) => {
			if (err) {
				if (err === "duplicate email") this.setState({"duplicate_email" : true});
				else if (err === "Username already in use.") {
					setContext({
						"snackbar_msg" : err,
						"snackbar_sev" : "error"
					});
					this.setState({"duplicate_username" : true});
				} 
				return log.debug(err);
			}

			logIn(username, password, (err) => {
				if (err) {
					log.debug(err);
					return;
				}

				this.sendVerificationToken();
			});
		});
	}

	handleSubmitOTP() {
		const { setContext } = this.context;
		const { onSuccess } = this.props;
		const { email, otp_code } = this.state;

		updateEmail(email, otp_code, err => {
			if (err) {
				if (err.response?.status === 401) {
					this.setState({"otp_code_bad" : true});
				} else if (err.response?.status === 400) {
					const msg = err.response?.data?.message;
					setContext({
						"snackbar_msg" : msg,
						"snackbar_sev" : "error"
					});
					setTimeout(onSuccess, 5000);
				} else {
					log.error(err);
				}
				return;
			}

			onSuccess();
		});
	}

	sendVerificationToken() {
		const { REACT_APP_OTP_TIMEOUT } = process.env;
		const { email } = this.state;
		const submitted_at = new Date().getTime();
		const lifespan = parseInt(REACT_APP_OTP_TIMEOUT);
		const expiration = submitted_at + lifespan;

		sendVerificationToken("email", email, (err) => {
			if (err) {
				log.debug(err);
				return;
			}

			this.setState({
				"otp_pending" : true,
				"otp_remaining" : parseInt(lifespan / 1000),
				"otp_timer_interval" : setInterval(() => {
					let tmp = (expiration - new Date().getTime());
					if (tmp <= 0) {
						clearInterval(this.state.otp_timer_interval);
						tmp = 0;
					}
					this.setState({"otp_remaining" : parseInt(tmp / 1000)});
				}, 1000)
			});
		});
	}

	isValidForm() {
		const {
			email, username,
			password, password_confirm,
			duplicate_email, duplicate_username
		} = this.state;
		let is_valid = true;

		is_valid = is_valid && isValidEmail(email);
		is_valid = is_valid && !duplicate_email;
		is_valid = is_valid && !duplicate_username;
		is_valid = is_valid && isValidUsername(username)["is_valid"];
		is_valid = is_valid && isValidPassword(password)["is_valid"];
		is_valid = is_valid && (password_confirm === password);

		return is_valid;
	}

	/**
	 * This JSON object is slightly easier to read/manage than actually typing out each React prop.
	 */
	getTextFieldProps(name) {
		const {
			username, email,
			password, password_confirm,
			duplicate_email, duplicate_username
		} = this.state;

		const email_helper_text = duplicate_email ? "Email already in use." : ((email && !isValidEmail(email)) ? "Invalid email." : "");
		const username_helper_text = duplicate_username ? "Username already in use." : ( (username && !isValidUsername(username)["is_valid"]) ? "Invalid username." : "This can be changed later");
		const password_helper_text = (password && !isValidPassword(password)["is_valid"]) ? "Password too weak." : "";
		const password_confirm_helper_text = (password_confirm && password_confirm !== password) ? "Passwords must match." : "";
		const phone_helper_text = "";

		return {
			"username" : {
				"className" : "signup-tf",
				"required" : true,
				"type" : "text",
				"name" : "username",
				"label" : "Display Name",
				"onChange" : this.handleChange,
				"error" : username_helper_text !== "This can be changed later",
				"helperText" : username_helper_text,
				"size" : "small",
				"InputProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
				"InputLabelProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
			},
			"email" : {
				"className" : "signup-tf",
				"required" : true,
				"size" : "small",
				"type" : "email",
				"name" : "email",
				"label" : "Email Address",
				"placeholder" : "JaneSmith@example.com",
				"onChange" : this.handleChange,
				"error" : email_helper_text !== "",
				"helperText" : email_helper_text,
				"InputProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
				"InputLabelProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
			},
			"password" : {
				"className" : "signup-tf",
				"required" : true,
				"size" : "small",
				"type" : "password",
				"name" : "password",
				"label" : "Password",
				"onChange" : this.handleChange,
				"error" : password_helper_text !== "",
				"helperText" : password_helper_text,
				"InputProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
				"InputLabelProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
			},
			"password_confirm" : {
				"className" : "signup-tf",
				"required" : true,
				"size" : "small",
				"type" : "password",
				"name" : "password_confirm",
				"label" : "Confirm Password",
				"onChange" : this.handleChange,
				"error" : password_confirm_helper_text !== "",
				"helperText" : password_confirm_helper_text,
				"InputProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
				"InputLabelProps" : {"sx" : {"typography" : "body2","color":"font.main"}},
			},
			"phone" : {
				"className" : "signup-tf",
				"inputProps" : {"className" : "signup-input"},
				"required" : false,
				"size" : "small",
				"type" : "phone",
				"name" : "phone",
				"label" : "Phone Number (Optional)",
				"placeholder" : "+1 (123) 456-7890",
				"onChange" : this.handleChange,
				"error" : phone_helper_text !== "",
				"helperText" : phone_helper_text,
				"InputProps" : {"sx" : {"typography" : "body2"}},
				"InputLabelProps" : {"sx" : {"typography" : "body2"}},
			},
			"verify_email" : {
				"size" : "small",
				"InputProps" : {"sx" : {"typography" : "body2"}},
				"InputLabelProps" : {"sx" : {"typography" : "body2"}},
				"sx" : {"margin" : "10px 0px 10px 0px"},
				"onChange" : this.handleChange
			}
		};
	}
}

export default SignupForm;
