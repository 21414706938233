import React from "react";
import PropTypes from "prop-types";

import log from "loglevel";

import { Button, Box, TextField, Typography } from "@mui/material";

import { updateEmail, sendVerificationToken } from "../../lib/account-ops.js";
import { isValidEmail } from "../../lib/string-validation.js";

import { AccountContext } from "../../context/AccountContext.js";

const { REACT_APP_OTP_TIMEOUT } = process.env;


class UpdateEmail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"is_open" : false,
			"new_email" : null,
			"new_email_confirm" : null,
			"verification_code" : null,
			"verification_pending" : false,
			"verification_code_bad" : false,
			"verification_ttl" : 0,
			"verification_expiration" : 0,
			"otp_remaining" : 0,
			"otp_timer_interval" : null
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSendVerification = this.handleSendVerification.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"onSubmit" : PropTypes.func.isRequired
	};

	render() {
		const {
			is_open, new_email, new_email_confirm, verification_pending, verification_code_bad, otp_remaining
		} = this.state;

		const is_valid_email = isValidEmail(new_email);
		const is_valid_email_confirm = new_email_confirm === new_email;

		const tf_props = {
			"type" : "email",
			"onChange" : this.handleChange,
			"size" : "small",
			"InputProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"InputLabelProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"sx" : { "margin" : "10px 0px 10px 0px" }
		};

		const otp_modal_text = otp_remaining ?
			`Your OTP will expire in ${otp_remaining} second${otp_remaining === 1 ? "" : "s"}.`
			:
			"Your OTP has expired.";

		return (
			<Box className="flex-col-center">
				<Typography
					className="update-toggle"
					variant="body2"
					color="primary"
					onClick={() => {
						this.setState({"is_open" : !is_open});
					}}
					sx={{
						"&:hover" : {
							"color" : "secondary.main"
						}
					}}
				>Change Email</Typography>

				{
					is_open && !verification_pending &&
					<Box className="flex-col-center">
						<TextField
							{...tf_props}
							name="new_email"
							label="New Email"
							error={new_email && !is_valid_email}
							helperText={(!new_email || is_valid_email) ? "" : "Invalid Email"} />

						<TextField
							{...tf_props}
							name="new_email_confirm"
							label="Confirm Email"
							error={!is_valid_email_confirm}
							helperText={is_valid_email_confirm ? "" : "Emails must match"} />

						<Button
							onClick={this.handleSendVerification}
							variant="contained"
							color="primary"
							sx={{"color" : "font.button"}}
						>Send Verification Code</Button>
					</Box>
				}

				{
					is_open && verification_pending &&
					<Box className="flex-col-center">
						<Typography
							varaint="h2"
							color="font.main"
						>{otp_modal_text}</Typography>

						{
							(otp_remaining > 0) &&
							<TextField
								{...tf_props}
								name="verification_code"
								label="Verification Code"
								error={verification_code_bad}
								helperText={verification_code_bad ? "Incorrect Verification Code" : ""} />
						}

						<Button
							onClick={otp_remaining ? this.handleSubmit : this.handleSendVerification}
							variant="contained"
							color="primary"
							sx={{"color" : "font.button", "marginTop" : "10px"}}
						>{ otp_remaining ? "Verify" : "Resubmit" }</Button>
					</Box>
				}
			</Box>
		)
	}

	handleChange(evt) {
		const { name, value } = evt.target;
		this.setState({[name] : value});
	}

	handleSendVerification() {
		const { new_email, new_email_confirm } = this.state;

		if (!isValidEmail(new_email)) return;
		else if (new_email_confirm !== new_email) return;

		const lifespan = parseInt(REACT_APP_OTP_TIMEOUT);
		const expiration = new Date().getTime() + lifespan;
		sendVerificationToken("email", new_email, (err) => {
			if (err) {
				log.error(err);
				return;
			}

			this.setState({
				"verification_pending" : true,
				"otp_remaining" : parseInt(lifespan / 1000),
				"otp_timer_interval" : setInterval(() => {
					let tmp = (expiration - new Date().getTime());
					if (tmp <= 0) {
						clearInterval(this.state.otp_timer_interval);
						tmp = 0;
					}
					this.setState({"otp_remaining" : parseInt(tmp / 1000)});
				}, 1000)
			});
		});
	}

	handleSubmit() {
		const { REACT_APP_OTP_TIMEOUT } = process.env;
		const { setContext } = this.context;
		const { onSubmit } = this.props;
		const { new_email, new_email_confirm, verification_code } = this.state;

		if (!isValidEmail(new_email)) return;
		else if (new_email_confirm !== new_email) return;

		updateEmail(new_email, verification_code, err => {
			if (err && err.response?.status !== 400) {
				if (err.response?.status === 401) {
					this.setState({ "verification_code_bad" : true });
				} else {
					log.error(err);
				}
				return;
			} else if (err?.response?.status === 400) {
				const msg = err.response.data?.message;
				setContext({
					"snackbar_msg" : msg,
					"snackbar_sev" : "error"
				});
			}

			/*const verifyInterval = setInterval(() => {
				const { verification_expiration } = this.state;

				const ttl = verification_expiration - new Date().getTime();
				this.setState({"verification_ttl" : ttl});
			}, 500);*/

			this.setState({
				"is_open" : false,
				"new_email" : null,
				"new_email_confirm" : null,
				"verification_code" : null,
				"verification_pending" : false,
				"verification_code_bad" : false,
				"verification_expiration" : new Date().getTime() + REACT_APP_OTP_TIMEOUT
			}, onSubmit);
		});
	}
}

export default UpdateEmail;
