import React from "react";
import PropTypes from "prop-types";

import {
	Accordion, AccordionSummary, AccordionDetails,
	Box, Typography, Container, Paper
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TermsConditions from "./TermsConditions.js";
import PrivacyPolicy from "./PrivacyPolicy.js";
import Disclaimers from "./Disclaimers.js";

class AboutPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"selected_accordion" : null
		};

		this.handleClick = this.handleClick.bind(this);
	}

	static contextType = undefined;
	static propTypes = {
		"match" : PropTypes.object.isRequired,
		"history" : PropTypes.object.isRequired,
		"location" : PropTypes.object.isRequired
	}

	componentDidMount() {

	}

	render() {
		const { selected_accordion } = this.state;

		return (
			<Box className="about-box">
				<Typography 
					variant="h1"
					color="font.main"
					sx={{ "textAlign" : "center", "margin" : "20px"}}
				>About StratSim</Typography>

				<Container component={Paper} className="about-cont">
					<Typography
						color="font.main"
						variant="subtitle1"
					>What is StratSim?</Typography>

					<Typography
						color="font.main"
						variant="body1"
						className="about-p"
					>
						The StratSim Engine was originally developed to help us speed up technical anysis, strategy creation, and validation. We wanted to provide others the same opportunity so we expanded further into a full platform that can be used by anyone who wants to test their strategies.
					</Typography>

					<Typography
						variant="body1"
						color="font.main"
						className="about-p"
					>
						StratSim is a code-free, automated backtesting platform. Focussing on Technical Anlysis, we understand the tedium of needing to manually track dozens or even hundreds of positions using a single strategy at time. While this process is definitely worthwhile, it is not particularly efficient. StratSim aims to resolve this by drastically reducing the amount of time it takes to build new strategies, as well as the time it takes to backtest them. Using our state-of-the-art  Backtesting Engine, you are able to backtest years of trades within seconds. Once the simulation is finished, you can use the Dashboard to review the positions taken, and the metrics of each position as well as the overall strategy.
					</Typography>
				</Container>

				<Container
					className="about-cont"
					sx={{
						"padding" : "0px !important",
						"margin-top" : "80px"
					}}
				>
					<Accordion
						disableGutters={true}
						expanded={selected_accordion === 1}
						onChange={evt => this.handleClick(1)}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
							<Typography 
								variant="h2"
								color="primary"
								sx={{ "textAlign" : "center", "margin" : "20px"}}
							>Disclaimers</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Disclaimers />
						</AccordionDetails>
					</Accordion>
					<Accordion
						disableGutters={true}
						expanded={selected_accordion === 2}
						onChange={evt => this.handleClick(2)}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
							<Typography 
								variant="h2"
								color="primary"
								sx={{ "textAlign" : "center", "margin" : "20px"}}
							>Terms and Conditions</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TermsConditions />
						</AccordionDetails>
					</Accordion>
					<Accordion
						disableGutters={true}
						expanded={selected_accordion === 3}
						onChange={evt => this.handleClick(3)}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>}>
							<Typography 
								variant="h2"
								color="primary"
								sx={{ "textAlign" : "center", "margin" : "20px"}}
							>Privacy Policy</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<PrivacyPolicy />
						</AccordionDetails>
					</Accordion>
				</Container>
			</Box>
		);
	}

	handleClick(evt) {
		this.setState({"selected_accordion" : evt});
	}
};

export default AboutPage;
