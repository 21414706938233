import React from "react";

import {
	createStrategy, createGroup, updateTemplate, getDefaultTemplate,
	pollContinuousSimulations
} from "../lib/strategy-ops.js";

/* Useful constants */

export const NUMBER_RGX = /^[-+]?\d+(\.\d*)?$/;
export const GROUP_ROUTE_RGX = /\/dashboard\/group-[0-9]*/;
export const STRATEGY_ROUTE_RGX = /\/dashboard\/group-[0-9]*\/strategy-[0-9]*/;
export const SIMULATION_ROUTE_RGX = /\/dashboard\/group-[0-9]*\/strategy-[0-9]*\/simulation-[0-9]*/;
export const REDIRECT_TIMEOUT_MS = 250;
export const VALID_BRANCHES = [ "group", "strategy", "simulation" ];

/* React Context*/

export const defaultStrategyContext = {
	// Stores all "branches": groups, strategies, simulations, and positions
	"groups" : [],
	"strategies" : {},
	"simulations" : {},
	"positions" : {},

	"selected_branch" : null, // groups, strategies, simulations, or positions
	"selected_leaf" : null, // the corresponding id.
	"selected_node_id" : "",
	"expanded_node_ids" : [],

	// Status booleans used for loading pages.
	"is_simulating" : false,

	"indicator_pool" : {},
	"security_pool" : [],
	// Current restrictions on granularities.
	"granularity_boundaries" : {
		"abs_min" : 1000 * 60 * 60, // 15 min
		"abs_max" : 1000 * 60 * 60 * 24, // 1 wk
		"min_step" : 1000 * 60 * 15 // 15 min
	},

	"is_loaded" : false,
	"is_loading" : false,
	"loading_failed" : false,
	"loading_msg" : null,
	// This information is persisted from the AccountContext. Important for determining permissions
	"membership_tier" : null,
	"unsaved_changes" : false,

	"simulation_data" : {
		"security" : null,
		"start_time" : null,
		"stop_Time" : null
	},

	"clicked_add_group" : false,

	"selected_position" : null,
	"selected_simulation" : null,
	"graph_start_time" : null,
	"graph_stop_time" : null,
	"collapse_list" : false,
	"sim_page" : 1, // TODO: This is "read only". Updating it doesn't affect the SimulationTable.
	"sim_page_size" : 10,
	"strat_page_size" : 10,
	"metric_sort_col" : 3,
	"metric_sort_direction" : "desc",
	"current_strategy_max_pages" : 1,
	"current_strategy_page" : 1,
};

export const StrategyContext = React.createContext(defaultStrategyContext);


export const addNewStrategy = (group_id, ctx) => {
	let { strategies, setContext, setPage } = ctx;
	const k = `group-${group_id}`;

	createStrategy(group_id, (err, new_strategy) => {
		if (err) return console.error(err);

		strategies[k].push(new_strategy);

		setContext({ strategies }, () => {
			setPage(`/dashboard/${k}/strategy-${new_strategy.id}`);
		});
	});
};

export const addNewGroup = (group_title, group_type, ctx, cb) => {
	const { setContext, setPage, groups, strategies } = ctx;

	getDefaultTemplate((err, default_template) => {
		if (err) return cb(err);

		createGroup(group_title, group_type, (err, res) => {
			if (err) return cb(err);
			const k = `group-${res.group_id}`;

			const new_group = {
				"id" : res.group_id,
				"type" : group_type,
				"title" : group_title,
				"template" : group_type === "template" ? default_template : null
			};

			setContext({
				"groups" : [
					...groups,
					new_group
				],
				"strategies" : {
					...strategies,
					[k] : []
				}
			});

			if (group_type === "template") {
				updateTemplate(res.group_id, default_template, (err, res) => {
					if (err) return cb(err);

					setPage(`/dashboard/${k}`);
					cb();
				});
			} else {
				setPage(`/dashboard/${k}`);
				cb();
			}

		});
	});
};

export const updateLeafSimulations = (ctx, err1, res1) => {
	const {
		selected_leaf, simulations, setContext
	} = ctx;
	const leaf_id = selected_leaf["id"];

	if (err1) {
		console.error(err1);
		setContext({
			"snackbar_sev" : "error",
			"snackbar_msg" : "Failed to update simulations."
		});
		return;
	}

	simulations[`strategy-${leaf_id}`] = res1["simulations"];
	selected_leaf["total_simulations"] = res1["total"];

	if (!selected_leaf["is_continuous"]) {
		setContext({ selected_leaf, simulations });
		return;
	}

	pollContinuousSimulations(leaf_id, (err2, res2) => {
		if (err2) {
			console.error(err2);
			setContext({
				"snackbar_sev" : "error",
				"snackbar_msg" : "Failed to update simulations."
			});
			return;
		}

		const { age_ms, submission_age } = res2;

		selected_leaf["age_ms"] = age_ms;
		selected_leaf["submission_age"] = submission_age;

		setContext({ selected_leaf, simulations });
	});
};
