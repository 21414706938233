/* TODO: Where should this value come from? Aside from hard-coding it? */
export const MIN_GRANULARITY_MS = 1000 * 60 * 10;
export const YEAR_MS = parseInt(1000 * 60 * 60 * 24 * 365.25);

export const isDuration = (duration_str) => {
	let units = ["s", "m", "h", "d", "w", "y"];
	if (typeof(duration_str) !== "string") return false; // Confirms datatype
	if (!duration_str || duration_str.length < 2) return false; // Confirms string is long enough to be a duration

	const tok = duration_str.split(" ");
	for (const d of tok) {
		const numeric = d.substring(0, d.length - 1);
		const unit = d.substring(d.length - 1);

		if (!units.includes(unit)) return false; // Checks for valid time unit.
		if (isNaN(numeric)) return false; // Number must be a number.
		// 		if (numeric === "" || isNaN(numeric)) return false; // Number must be a number.
	}

	return true;
};

export const durationToMs = (duration_str) => {
	if (!isDuration(duration_str)) return null;

	const unit_ms_map = {
		"s" : 1000,
		"m" : 1000 * 60,
		"h" : 1000 * 60 * 60,
		"d" : 1000 * 60 * 60 * 24,
		"w" : 1000 * 60 * 60 * 24 * 7,
		"y" : 1000 * 60 * 60 * 24 * 52,
	};

	const tok = duration_str.split(" ");
	let total_ms = 0;
	for (const d of tok) {
		const unit = d.charAt(d.length - 1);
		const numeric = Number(d.substring(0, d.length - 1));

		if (unit in unit_ms_map) total_ms += (numeric * unit_ms_map[unit]);
		else return null; /* TODO: Error condition? */
	}

	/*
	const max_dur = 1000 * 60 * 60 * 24;
	const min_dur = 1000 * 60 * 60;
	if (total_ms < min_dur || total_ms > max_dur) return null;
	*/

	return total_ms;
};

export const msToDuration = (gap_ms) => {
	if (!gap_ms) return "0s";

	// Amount of milliseconds in each duration.
	const second_ms = 1000;
	const minute_ms = second_ms * 60;
	const hour_ms = minute_ms * 60;
	const day_ms = hour_ms * 24;
	const week_ms = day_ms * 7;
	const year_ms = week_ms * 52;
	//const ten_minutes = (minute_ms * 10);

	// Enforces that the duration is divisible by 10 minutes.
	//let total_ms = gap_ms - (gap_ms % ten_minutes);
	let total_ms = gap_ms;
	let duration_arr = [];

	// Converts gap_ms into distinct units of time.
	const years = Math.floor(total_ms / year_ms); // Gets unit.
	if (years > 0) duration_arr.push(`${years}y`);
	total_ms -= (years * year_ms);

	const weeks = Math.floor(total_ms / week_ms); // Gets unit.
	if (weeks > 0) duration_arr.push(`${weeks}w`);
	total_ms -= (weeks * week_ms);

	const days = Math.floor(total_ms / day_ms);
	if (days > 0) duration_arr.push(`${days}d`);
	total_ms -= (days * day_ms);

	const hours = Math.floor(total_ms / hour_ms);
	if (hours > 0) duration_arr.push(`${hours}h`);
	total_ms -= (hours * hour_ms);

	const minutes = Math.floor(total_ms / minute_ms);
	if (minutes > 0) duration_arr.push(`${minutes}m`);
	total_ms -= (minutes * minute_ms);

	const seconds = Math.floor(total_ms / second_ms);
	if (seconds > 0) duration_arr.push(`${seconds}s`);
	total_ms -= (seconds * second_ms);

	return duration_arr.join(" ");
}

