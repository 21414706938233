import React from "react";
import PropTypes from "prop-types";

import {
	Box, Button, Checkbox, Paper,
	Grid, IconButton, Tooltip, Typography
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { StrategyContext } from "../../../context/StrategyContext.js";
import CenterModal from "../../CenterModal.js";

import { SIMULATION_METRICS } from "../../../lib/metric-ops.js";

class MetricsGrid extends React.Component {
	constructor(props) {
		super(props);

		const metric_options = SIMULATION_METRICS.filter(sm => sm["is_metric"]);

		this.state = {
			"selected_metrics" : metric_options.filter(mo => mo["is_default"]),
			"new_selected_metrics" : metric_options.filter(mo => mo["is_default"]),
			"show_selector" : false,
			metric_options
		};

		this.renderItems = this.renderItems.bind(this);
		this.renderMetricOptions = this.renderMetricOptions.bind(this);
	}

	static contextType = StrategyContext;

	static propTypes = {
		"metrics" : PropTypes.object,
		"simulation_data" : PropTypes.object,
		"positions" : PropTypes.array
	}

	render() {
		const { show_selector, new_selected_metrics } = this.state;

		return (
			<Box className="metrics-grid">
				<Box
					className="flex-row-center"
					sx={{"justifyContent" : "start"}}
				>
					<Typography
						color="font.main"
						variant="h2"
					>Performance Metrics</Typography>
					<Tooltip title="Customize Metrics View">
						<IconButton
							onClick={() => {
								this.setState({
									"show_selector" : true
								});
							}}
						>
							<SettingsIcon />
						</IconButton>
					</Tooltip>
				</Box>

				<Grid
					container
					spacing={0}
					sx={{
						"display" : "flex",
						"justifyContent" : "space-between"
					}}
				>
					{ this.renderItems() }
				</Grid>

				<CenterModal
					is_open={show_selector}
					title="Select Which Metrics to Display"
					onClose={() => {
						this.setState({"show_selector" : false});
					}}
				>
						<Grid
							container
							spacing={0}
							sx={{
								"display" : "flex",
								"flexDirection" : "row",
								"justifyContent" : "space-around",
								"alignItems" : "start"
							}}
						>
							{ this.renderMetricOptions() }
						</Grid>

						<Button
							variant="contained"
							sx={{
								"color" : "font.button",
								"marginTop" : "30px"
							}}
							color="primary"
							onClick={() => {
								this.setState({
									"show_selector" : false,
									"selected_metrics" : new_selected_metrics
								});
							}}
						>
							Save
						</Button>
				</CenterModal>
			</Box>
		);
	}

	renderItems() {
		const { metrics } = this.props;
		const { selected_metrics } = this.state;

		return selected_metrics.map(sm => {
			return (
				<Grid
					item
					key={sm["long_name"]}
					component={Paper}
					xs={3}
					sm={2}
					className="metric-grid-tile"
				>
					<Box className="flex-row-space-between">
						<Typography color="font.main"
							variant="body2"
						>{sm["short_name"]}</Typography>
					{
						sm.description &&
						<Tooltip title={sm.description}>
							<InfoOutlinedIcon sx={{"color":"font.main"}}/>
						</Tooltip>
					}
					</Box>

					<Typography color="font.main"
					variant="h2"
					className="metric-tile-value"
					>{sm.calculate ? sm.calculate(metrics) : "1.0"}</Typography>
				</Grid>
			);
		});
	}

	renderMetricOptions() {
		const { new_selected_metrics, metric_options } = this.state;
		// TODO: This is where we would connect to metric-ops?

		const handleCheck = (mo, is_checked) => {
			if (is_checked) {
				this.setState({
					"new_selected_metrics" : new_selected_metrics.filter(sm => sm.id !== mo.id)
				});
			} else {
				this.setState({
					"new_selected_metrics" : [ ...new_selected_metrics, mo ]
				});
			}
		}

		return metric_options.map(mo => {
			const is_checked = new_selected_metrics.map(sm => sm.id).includes(mo.id);
			const long_name = mo["long_name"];

			return (
				<Grid
					item
					key={long_name}
					xs={2}
					onClick={() => {
						handleCheck(mo, is_checked);
					}}
					className="metric-option-tile"
				>
					<Checkbox checked={is_checked} />
					<Typography color="font.main">{long_name}</Typography>
					{
						mo.description &&
						<Tooltip title={mo.description}>
							<InfoOutlinedIcon sx={{"color":"font.main"}}/>
						</Tooltip>
					}
				</Grid>
			);
		});
	}
}

export default MetricsGrid;
