import React from "react";
import PropTypes from "prop-types";

// import axios from "axios";
import log from "loglevel";

import { Box, TextField, Button, Typography } from "@mui/material";

import { sendVerificationToken } from "../../lib/account-ops.js";
import { isValidEmail, isValidPhoneNumber } from "../../lib/string-validation.js";

class SendVerificationCode extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"is_email" : true,
			"email" : null,
			"phone" : null
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	static propTypes = {
		"onSend" : PropTypes.func.isRequired
	};

	render() {
		const { is_email, email, phone } = this.state;

		const is_valid = is_email ? isValidEmail(email) : isValidPhoneNumber(phone);

		const email_props = {
			"className" : "login-tf",
			"type" : "email",
			"name" : "email",
			"label" : "Email Address",
			"onChange" : this.handleChange,
			"size" : "small",
			"InputProps" : {"sx":{"typography":"body2"}},
			"InputLabelProps" : {"sx":{"typography":"body2"}},
			"error" : email && !is_valid,
		};

		const phone_props = {
			"className" : "phone-tf",
			"size" : "small",
			"inputProps" : {"className" : "phone-input"},
			"type" : "text",
			"name" : "phone",
			"label" : "Phone Number",
			"onChange" : this.handleChange,
			"error" : phone && !is_valid,
			"helperText" : "Formats: (111) 222-3333, 1112223333, 111-222-3333",
			"InputProps" : {"sx" : {"typography" : "body2"}},
			"InputLabelProps" : {"sx" : {"typography" : "body2"}}
		};

		return (
			<Box
				sx={{
					"display" : "flex",
					"flexDirection" : "column",
					"justifyContent" : "center",
					"alignItems" : "center",
				}}
			>
				{
					is_email ?
					<TextField {...email_props} />
					:
					<TextField {...phone_props} />
				}
				<Typography color="font.main"
					variant="body3"
					className="verification-input-toggle-text"
					onClick={() => {
						this.setState({"is_email" : !is_email});
					}}
				>Use {is_email ? "Phone" : "Email"} instead</Typography>

				<Button
					variant="contained"
					onClick={this.handleSubmit}
					sx={{
						"marginTop" : "40px",
						"color" : "font.button"
					}}
					disabled={!is_valid}
				>Send Verification Code</Button>
			</Box>
		);
	}

	handleChange(evt) {
		const { name, value } = evt.target;

		this.setState({ [name] : value });
	}

	handleSubmit() {
		const { onSend } = this.props;
		const { is_email, email, phone } = this.state;

		const medium = is_email ? "email" : "sms";
		const recipient = is_email ? email : phone;

		sendVerificationToken(medium, recipient, (err) => {
			if (err) {
				log.debug("Failed to send verification token");
				// TODO: Send a Snackbar alert?
				return;
			}

			onSend();
		});
	}


}

export default SendVerificationCode;
