import React from "react";
//import PropTypes from "prop-types";

import { Container, Typography } from "@mui/material";

class PrivacyPolicy extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static contextType = undefined;
	static propTypes = {};

	render() {
		return (
			<Container>
				<Typography color="font.main" variant="h3">1. StratSim Privacy Policy</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"20px"}}>
					StratSim.com ("StratSim", "We", "Our Website") is determined to safeguard any private or personal that you provide us while using our website. All consumers have a right to be informed about how their data is being collected and used. This document outlines how StratSim acquires user information, as well as how We use that information to provide the best possible service.
				</Typography>
				<br />

				<Typography color="font.main" variant="h3">2. Types of Data Collected</Typography>
				<Typography color="font.main" variant="subtitle2" style={{"marginLeft":"20px"}}>2a. Opt-In Personally Identifiable Information</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					The user's email address, phone number and IP address are collected throughout the use of the website. The email address is provided by the user during account creation, and can be changed at any time. A phone number is not required for the operation of the account, and the user may choose to provide one if desired for the purposes of website notifications and identity verification. In both of these cases, the user is clearly prompted for this information and may choose not to do so.
				</Typography>
				<Typography color="font.main" variant="subtitle2" style={{"marginLeft":"20px"}}>2b. Required Personally Identifiable Information</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					The user's IP address is collected every time they visit our website. This is specifically for security and performance purposes, as will be explained in the next section.
				</Typography>
				<Typography color="font.main" variant="subtitle2" style={{"marginLeft":"20px"}}>2c. Payment Information</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					StratSim does not store any payment information provided through the website. All payment information is processed through a secure Payment Provider, Braintree. For more information on Braintree's Privacy policy, please read more here: https://www.braintreepayments.com/legal/braintree-privacy-policy
				</Typography>
				<Typography color="font.main" variant="subtitle2" style={{"marginLeft":"20px"}}>2d. Cookies and Tracking</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					We do not use Cookies to store any personal information. We only use Cookies to maintain your web session, in order to enable features such as account log in or other basic website functionality. These are considered "essential" cookies and do not compromise your personal information.
				</Typography>
				<br />

				<Typography color="font.main" variant="h3">3. Use of Data</Typography>
				<Typography color="font.main" variant="subtitle2" style={{"marginLeft":"20px"}}>
					3a. The user's email address and phone number may be used for the following reasons:
				</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					To send verification codes, with the purpose of confirming the user's identity
				</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					Send opt-in only notifications of various events and occurances on the website.
				</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					Send opt-in only updates about the StratSim platform
				</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					To inform the user of suspicious activity on their account.
				</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					To communicate with the user during customer support incidences.
				</Typography>
				<Typography color="font.main" variant="subtitle2" style={{"marginLeft":"20px"}}>
					3b. The user's IP Address is collected for the following purposes:
				</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					To track suspicious activity on an account.
				</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					To perform threat detection against malicious IP Addresses.
				</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					To perform throttling / rate limiting to prevent service exploitation.
				</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft":"40px"}}>
					To verify that the user is using StratSim within an approved region.
				</Typography>
				<br />

				<Typography color="font.main" variant="h3">4. Disclosure of Personal Information</Typography>
				<Typography color="font.main" variant="body1" style={{"marginLeft" : "20px"}}>
Customer payment information is stored within the payments provider Braintree, and we do not have access to this data. Other personal information, such as the email address, phone number, and IP address will not be shared with third parties.

				</Typography>

			</Container>
		);
	}
}

export default PrivacyPolicy;

