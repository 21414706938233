import React from "react";
import PropTypes from "prop-types";

import log from "loglevel";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import CenterModal from "./CenterModal.js";

class LoadingModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"loading_start" : null,
			"loading_duration" : null,
			"loading_interval" : null,
			"loading_timeout" : 10000,
			"timer_update_interval" : 200
		};

		this.updateTimer = this.updateTimer.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}

	static propTypes = {
		"is_open" : PropTypes.bool.isRequired,
		"message" : PropTypes.string,
		"allow_timeout" : PropTypes.bool
	}

	componentDidMount() {
		log.debug("LoadingModal componentDidMount");
	}

	componentDidUpdate(prevProps) {
		const was_open = prevProps.is_open;
		const is_open = this.props.is_open;
		const { timer_update_interval } = this.state;

		const old_msg = prevProps.message;
		const new_msg = this.props.message;
		const msg_changed = new_msg !== old_msg;

		log.debug("LoadingModal componentDidUpdate");

		let loading_start = null, loading_duration = null, loading_interval = null;

		if (was_open && !is_open) {
			clearInterval(this.state.loading_interval);
			this.setState({ loading_start, loading_duration, loading_interval });
		} else if ((is_open && !this.state.loading_interval) || msg_changed) {
			if (this.state.loading_interval) clearInterval(this.state.loading_interval);
			// Loading has commenced.
			loading_start = new Date().getTime();
			loading_duration = 0;
			loading_interval = setInterval(this.updateTimer, timer_update_interval);
			this.setState({ loading_start, loading_duration, loading_interval });
		}
	}

	componentWillUnmount() {
		const { loading_interval } = this.state;

		if (loading_interval) clearInterval(loading_interval);
	}

	render() {
		const { is_open, message, allow_timeout } = this.props;
		const { loading_duration, loading_timeout } = this.state;

		const show_timeout = (allow_timeout !== false) && (loading_duration >= loading_timeout);

		return (
			<CenterModal
				is_open={is_open}
				title="Loading..."
			>
				<Typography
					color="font.main"
					variant="h1"
				>{message}</Typography>

				{
					show_timeout ?
					<Box
						className="flex-col-center"
						sx={{ "marginTop" : "30px" }}
					>
						<Typography
							color="font.main"
							variant="body1"
						>This page is taking a while to load.</Typography>
						<Box
							className="flex-row-space-around"
							sx={{"width" : "100%"}}
						>
							<Button
								variant="contained"
								color="primary"
								sx={{"color":"font.button"}}
								onClick={() => {
									this.setState({
										"loading_start" : new Date().getTime(),
										"loading_timeout" : loading_timeout * 2 // Extends time before next warning.
									});
								}}
							>Keep Waiting</Button>
							<Button
								variant="contained"
								color="primary"
								sx={{"color":"font.button"}}
								onClick={this.handleCancel}
							>Refresh Page</Button>
						</Box>
					</Box>
					:
					<CircularProgress sx={{"marginTop" : "30px"}}/>
				}
			</CenterModal>
		);

	}

	/**
	 * Called once every {internval} that the LoadingModal is open.
	 */
	updateTimer() {
		const { loading_start } = this.state;
		const delta = new Date().getTime() - loading_start;
		this.setState({"loading_duration" : delta});
	}

	/**
	 * Re-navigates to /dashboard with har dashboard.
	 */
	handleCancel() {
	/*
		const current_url = window.location.href;
		const tok = current_url.split("/");
		const new_url = `${tok[0]}//${tok[2]}/dashboard`;

		window.location.href = new_url;
	*/
		/* Simpler logic, just refresh the page on cancel. */
		window.location.reload();
	}
}

export default LoadingModal;
