import React from "react";
import PropTypes from "prop-types";

import { Box, Button, Container, Paper, Typography, TextField } from "@mui/material";

import { AccountContext } from "../../context/AccountContext.js";
import { logIn } from "../../lib/account-ops.js";

import ResetPasswordModal from "./ResetPasswordModal.js";

class LoginForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"username" : null,
			"password" : null,
			"show_forgot_password" : false,
			"forgot_step" : 0,
			"bad_login" : false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"onSuccess" : PropTypes.func.isRequired
	};

	render() {
		const { bad_login, show_forgot_password, forgot_step } = this.state;

		const username_props = {
			"className" : "login-tf",
			"required" : true,
			"type" : "text",
			"name" : "username",
			"label" : "Username",
			"onChange" : this.handleChange,
			"color" : "secondary",
			"size" : "small",
			"InputProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"InputLabelProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"error" : bad_login,
			"helperText" : bad_login ? "Please check your credentials" : ""
		};

		const password_props = {
			"className" : "login-tf",
			"required" : true,
			"type" : "password",
			"name" : "password",
			"label" : "Password",
			"onChange" : this.handleChange,
			"color" : "secondary",
			"size" : "small",
			"InputProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"InputLabelProps" : {"sx":{"typography":"body2","color":"font.main"}},
			"error" : bad_login,
			"helperText" : bad_login ? "Please check your credentials" : ""
		};

		return (
			<Container
				sx={{
					"backgroundColor" : "background.paper"
				}}
				component={Paper}
				className="login-widget"
			>
				<Box className="flex-col-center">
					<TextField
						sx={{
							"&:not($focused)" : {
								"color" : "error.main",
								"borderColor" : "error.main",
							}
						}}
						{...username_props}
					/>
					<TextField {...password_props} />

					<Typography
						variant="body3"
						color="font.main"
						onClick={() => {
							this.setState({"show_forgot_password" : true});
						}}
						sx={{
							"width" : "100%",
							"textAlign" : "center",
							"&:hover" : {
								"color" : "secondary.main",
								"cursor" : "pointer"
							}
						}}
					>Forgot Password</Typography>

					<Button
						onClick={this.handleSubmit}
						variant="contained"
						color="primary"
						sx={{
							"margin" : "40px 0px 0px 0px",
							"color" : "font.button"
						}}
					>Log In</Button>
				</Box>

				<ResetPasswordModal
					is_open={show_forgot_password}
					current_step={forgot_step}
					onClose={() => {
						this.setState({"show_forgot_password" : false, "forgot_step" : 0})
					}} />
			</Container>
		);
	}

	handleChange(evt) {
		const { name, value } = evt.target;
		this.setState({[name] : value === "" ? null : value, "bad_login" : false});
	}

	handleSubmit() {
		const { setContext } = this.context;
		const { onSuccess } = this.props;
		const { username, password } = this.state;

		logIn(username, password, (err) => {
			if (err) {
				const res = err.response;
				if (res && res.status === 401) {
					// Login failed. Set error and helpertext?
					setContext({
						"snackbar_msg" : "Bad username address or password.",
						"snackbar_sev" : "error"
					});
					this.setState({"bad_login" : true});
				}

				return;
			}

			onSuccess();
		});
	}

}

export default LoginForm;
