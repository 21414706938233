import React from "react";
// import PropTypes from "prop-types";

import log from "loglevel";

import {
	Button, Container, Typography
} from "@mui/material";

import { AccountContext } from "./../context/AccountContext.js";
import { acceptCookies } from "./../lib/account-ops.js";

class CookieBanner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {};

	render() {
		const { cookies_acknowledged } = this.context.customizations;

		return (
			<Container
				className="flex-row-space-around"
				sx={{
					"position" : "fixed",
					"bottom" : "0",
					"backgroundColor" : "primary.main",
					"width" : "100% !important",
					"maxWidth" : "100% !important",
					"height" : "75px",
					"zIndex" : "10",
					"display" : cookies_acknowledged ? "none !important" : undefined,
				}}
			>
				<Typography
					variant="body1"
					color="font.button"
				>
					We only use Essential Cookies for logging into your account and other basic site functionality.
				</Typography>
				<Button
					variant="contained"
					color="secondary"
					sx={{
						"color" : "font.button"
					}}
					onClick={this.handleSubmit}
				>I Understand</Button>
			</Container>
		);
	}

	handleSubmit() {
		const { setContext, customizations } = this.context;

		acceptCookies((err) => {
			if (err) {
				log.error(err);
				setContext({
					"snackbar_msg" : "Failed to accept cookies, please report this incident.",
					"snackbar_sev" : "error"
				});
				return;
			}

			setContext({
				"customizations" : {
					...customizations,
					"cookies_acknowledged" : true
				}
			});
		});
	}
}

export default CookieBanner;
