import React from "react";
import PropTypes from "prop-types";

import {
	Accordion, AccordionSummary, AccordionDetails,
	Box, Typography
} from "@mui/material";

import { AccountContext } from "./../context/AccountContext.js";
import ContactUs from "./ContactUs.js";

class SupportPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"selected_accordion" : null,
		};

		this.handleClick = this.handleClick.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"match" : PropTypes.object.isRequired,
		"history" : PropTypes.object.isRequired,
		"location" : PropTypes.object.isRequired
	}

	render() {
		const { selected_accordion } = this.state;

		return (
			<Box
				className="flex-col-center support-box"
			>
				<Typography 
					variant="h1"
					color="font.main"
					sx={{ "textAlign" : "center", "margin" : "20px"}}
				>StratSim Support</Typography>

				<Typography 
					variant="h2"
					color="font.main"
					sx={{ "textAlign" : "center", "margin" : "20px"}}
				>Frequently Asked Questions</Typography>

				<Accordion
					disableGutters={true}
					expanded={selected_accordion === 1}
					onClick={evt => {this.handleClick(1)}}
				>
					<AccordionSummary>
						<Typography
							variant="subtitle1"
							color="font.main"
						>What are Strategies?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body1"
							color="font.main"
							className="about-p"
						>
							Within the StratSim platform, a Strategy is a collection of Technical Indicators (e.g. Moving Averages) as well as their "signals", which define the conditions with which to trade. Additionally, there are some parameters used to control overall trading conditions, such as applying a stop loss or take profit. All these values together represent a single "Strategy" which you can submit to the Engine for simulation.
						</Typography>

						<Typography
							variant="body1"
							color="font.main"
							className="about-p"
						>
							After your Strategy has been designed, you may specify a security and time range that you'd like to test on. We currently support X US Stocks and Y Foreign Exchange Pairs. Depending on the security, we have data going pack to the year 2000.
						</Typography>
					</AccordionDetails>
				</Accordion>



				<Accordion
					disableGutters={true}
					expanded={selected_accordion === 2}
					onClick={evt => {this.handleClick(2)}}
				>
					<AccordionSummary>
						<Typography
							variant="subtitle1"
							color="font.main"
						>What are Templates?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body1"
							color="font.main"
							className="about-p"
						>
							Being able to specifically fine-tune your strategies with immediate backtesting feedback is useful, but we have found that even this process can become tiresome over time. For this reason, we have also introduced to the Dashboard the concept of "Templates", which define acceptable boundaries for a set of trading strategies, and then randomly generates strategies within these boundaries for review. You could submit 1,000 different strategies, for example, testing various combinations of SMA crossovers and RSI momentum.
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion
					disableGutters={true}
					expanded={selected_accordion === 3}
					onClick={evt => {this.handleClick(3)}}
				>
					<AccordionSummary>
						<Typography
							variant="subtitle1"
							color="font.main"
						>What is Continuous Submission?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body1"
							color="font.main"
							className="about-p"
						>
							The highest subscription tier offers "Continuous Submission" to the Backtestng Engine. You simply set an interval and the strategy/template will be submitted repeatedly. Additionally, you may enable SMS / email notifications of the simulation progress. Of course, you may disable these notifictions at any time.
						</Typography>
					</AccordionDetails>
				</Accordion>

				<ContactUs />
			</Box>
		);
	}

	handleClick(evt) {
		this.setState({"selected_accordion" : evt});
	}
}

export default SupportPage;

