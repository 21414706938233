/**
 * TODO: Add checks to confirm that the CSV length is consistent.
 */
export const getColCount = (csv) => {
	return csv.split("\r\n")[0].split(",").length;
}


/**
 * As CSV parsing goes, this is horribly innefficient.
 * TODO: Modify this to pull out all columns instead of one at a time?
 *
 * @param {*} csv
 * @param {*} index
 * @returns
 */
export const getColAsSeries = (csv, index) => {
	const rows = csv.split("\r\n"); // TODO: Drop Header row

	return rows.slice(1, rows.length - 1).map(r => {
		const tok = r.split(",");

		return {
			"time" : localizeUnixTime(parseInt(tok[0]) / 1000),
			"value" : parseFloat(tok[index])
		};
	});
}


const tz_offset = new Date().getTimezoneOffset() * 60;
export const localizeUnixTime = (ts) => {
	return ts - tz_offset;
};

export const delocalizeUnixTime = (ts) => {
	return ts + tz_offset;
};
