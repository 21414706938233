import React from "react";
import PropTypes from "prop-types";

import log from "loglevel";

import { Box, Typography } from "@mui/material";

import { AccountContext, defaultAccountContext } from "../../context/AccountContext.js";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";

import { whoAmI } from "../../lib/account-ops.js";

class Signin extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"is_login" : true,
			"show_widget" : false,
		};

		this.handleSignin = this.handleSignin.bind(this);
		this.checkReferrer = this.checkReferrer.bind(this);
	}

	static contextType = AccountContext;
	static propTypes = {
		"match" : PropTypes.object.isRequired,
		"history" : PropTypes.object.isRequired,
		"location" : PropTypes.object.isRequired
	};

	componentDidMount() {
		const { REACT_APP_ENV } = process.env;

		// Optionally hide the sign-in widget on production.
		const hide_on_prod = false;
		this.setState({
			"show_widget" : hide_on_prod ? REACT_APP_ENV !== "prod" : true,
			"is_login" : this.props.location.search !== "?is_login=false"  // lol nice parsing bro
		});
	}

	render() {
		const { is_login, show_widget } = this.state;

		return !show_widget ? (<div />) : (
			<Box className="flex-col-center signin-box">
				<Typography color="font.main"
					sx={{
						"width" : "fit-content",
						"marginBottom" : "20px"
					}}
					variant="h2"
				>
					{ is_login ? "Log in" : "Sign up" }
				</Typography>

				{
					is_login ?
						<LoginForm onSuccess={this.handleSignin} />
						:
						<SignupForm onSuccess={this.handleSignin} />
				}

				<Typography color="font.main"
					className="signin-toggle-text"
					variant="body3"
					onClick={() => {
						this.setState({ "is_login" : !is_login });
					}}
					sx={{
						"&:hover" : { "color" : "secondary.main" }
					}}
				>
					{ is_login ? "No account? Sign up" : "Already registered? Log in" }
				</Typography>
			</Box>
		);
	}

	checkReferrer() {
		const { location } = this.context;

		const search_term = "ref";
		const href = window.location.href;
		const idx = href.search(search_term);

		const has_referrer = location?.state?.referrer || idx > -1;
		console.log({href, idx, has_referrer, location});
		if (!has_referrer) return null;

		return location?.state?.referrer || atob(href.substring(idx + 4));
	}

	handleSignin() {
		const { setContext } = this.context;
		const { history } = this.props;

		log.debug("Signin component handleSignin");

		const referrer = this.checkReferrer();
		log.debug(`Detected referer: ${referrer}`);
		if (referrer) history.push(referrer);

		whoAmI((err, context_data) => {
			if (err) {
				// TODO: Show some snackbar alert here?
				return log.debug(err);
			}

			// Determines which account context to use based on whoami.
			const { is_authenticated, membership_expiration } = context_data;
			const new_context = is_authenticated ? context_data : defaultAccountContext;
			const is_expired = is_authenticated && (new Date().getTime() > membership_expiration);

			setContext({...new_context, is_expired});
		});
	}
}

export default Signin;
